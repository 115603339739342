import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { Tag } from '~/components/Tag';

type ArtistAppliedFilter = {
  category: string;
  value: string;
  displayName: string;
};

interface ArtistAppliedFilterListProps {
  appliedFilters: ArtistAppliedFilter[];
  onRemoveFilter: (value: string) => void;
}

const ArtistAppliedFilterList = ({
  appliedFilters,
  onRemoveFilter,
}: ArtistAppliedFilterListProps) => {
  const { t } = useTranslation('roster');
  return (
    <div
      className="flex items-center gap-md py-md"
      aria-label="Applied filters"
    >
      <div className="text-body-sm">{t('Filter(s) applied:')}</div>
      {appliedFilters.map(({ category, value, displayName }) => {
        return (
          <Tag key={value} variant="secondary">
            {category}: {displayName}
            <button onClick={() => onRemoveFilter(value)}>
              <Icon type="x" variant="regular" size="xs" />
            </button>
          </Tag>
        );
      })}
    </div>
  );
};

ArtistAppliedFilterList.displayName = 'ArtistAppliedFilterList';
export { ArtistAppliedFilterList };
