import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { getLabelPerfReport } from '../api/label-perf';
import { Header } from './Header';

export function LabelPerf() {
  const { t } = useTranslation('label-perf');

  // Test query to check api
  const { data } = useQuery({
    queryKey: ['label-perf'],
    queryFn: () => getLabelPerfReport([]),
  });

  // Test api response
  console.log(data);

  return (
    <>
      <div className="flex justify-between items-center">
        <Header />
      </div>
      <div className="w-full">
        <div className="flex flex-col w-[21em] gap-[1.5em]">
          <div className="font-dazzed font-bold text-[7em] text-tertiary-default">
            <h2>WMG</h2>
            <h2 className="-mt-4xl">ONE</h2>
          </div>
          <p className="text-headline-md text-white font-bold">
            {t('header.comingSoon')}
          </p>
        </div>
      </div>
    </>
  );
}

LabelPerf.displayName = 'LabelPerf';
