import type { DropdownProps } from '../Dropdown';

export const assertMultiSelect = <TMultiselect extends boolean>({
  multiselect,
  value,
}: Pick<DropdownProps<TMultiselect>, 'multiselect' | 'value'>) => {
  if (!multiselect) {
    return;
  }
  if (!!value && !Array.isArray(value)) {
    throw new Error('value must be an array for multiselect dropdown');
  }
};
