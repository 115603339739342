export function Spotify({ className }: { className?: string }) {
  return (
    <svg
      role="img"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 0.53125C13.7812 0.53125 17.7188 4.46875 17.7188 9.25C17.7188 14.0664 13.7812 17.9688 9 17.9688C4.18359 17.9688 0.28125 14.0664 0.28125 9.25C0.28125 4.46875 4.18359 0.53125 9 0.53125ZM12.5156 13.3633C12.7969 13.3633 13.043 13.1523 13.043 12.8359C13.043 12.5195 12.9375 12.3789 12.7266 12.2383C10.1953 10.7617 7.27734 10.6914 4.39453 11.3242C4.11328 11.3945 3.90234 11.5703 3.90234 11.9219C3.90234 12.2031 4.11328 12.4844 4.46484 12.4844C4.57031 12.4844 4.74609 12.4141 4.88672 12.3789C7.41797 11.8867 9.94922 11.9219 12.1641 13.2578C12.2695 13.3281 12.375 13.3633 12.5156 13.3633ZM13.4648 11.0781C13.8516 11.0781 14.1328 10.7617 14.168 10.375C14.168 10.0938 14.0273 9.84766 13.7461 9.70703C12.0234 8.65234 9.80859 8.08984 7.52344 8.08984C6.08203 8.08984 5.0625 8.30078 4.07812 8.58203C3.72656 8.6875 3.55078 8.93359 3.55078 9.28516C3.55078 9.67188 3.86719 9.98828 4.21875 9.98828C4.39453 9.98828 4.46484 9.91797 4.64062 9.88281C7.55859 9.10938 10.8281 9.63672 13.043 10.9375C13.1484 10.9727 13.2891 11.0781 13.4648 11.0781ZM14.5547 8.40625C14.9766 8.40625 15.3633 8.05469 15.3633 7.5625C15.3633 7.14062 15.1875 6.92969 14.9062 6.78906C12.9727 5.62891 10.2656 5.10156 7.69922 5.10156C6.1875 5.10156 4.81641 5.27734 3.58594 5.62891C3.26953 5.73438 2.95312 5.98047 2.95312 6.47266C2.95312 6.92969 3.30469 7.31641 3.76172 7.31641C3.9375 7.31641 4.11328 7.24609 4.21875 7.21094C7.13672 6.40234 11.6016 6.75391 14.0977 8.26562C14.2734 8.33594 14.3789 8.40625 14.5547 8.40625Z" />
    </svg>
  );
}
Spotify.displayName = 'Spotify';
