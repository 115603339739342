import type { OktaAuth } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';

import { logger } from '~/lib/logger';

import { bffClient } from '../lib/bff-client';

/**
 * Safe sign-out executor.
 */
const signOut = async (oktaAuth: OktaAuth) => {
  if (await oktaAuth.isAuthenticated()) {
    try {
      await oktaAuth.session?.close();
    } catch (e) {
      logger.error('Failed to close OKTA session', e);
    }

    try {
      await oktaAuth.signOut({ revokeRefreshToken: true });
    } catch (e) {
      logger.error('Failed to sign out via OKTA', e);
    }

    sessionStorage.clear();
  }
};

/**
 * /logout
 *
 * Page dedicated to safely initiate logout procedures.
 */
export const Logout = () => {
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await Promise.all([bffClient.logout(), signOut(oktaAuth)]);
      } catch (error) {
        logger.error('Logout error:', error);
      }
    };

    handleLogout();
  }, [oktaAuth]);

  return null;
};

Logout.displayName = 'Logout';
