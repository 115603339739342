import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { Tooltip } from '~/components/Tooltip';

import type { Role } from '../api/lib/schemas';
import { RoleMap } from '../constants/RoleMap';

export const UserRole = ({ roles }: { roles: string[] }) => {
  const { t } = useTranslation('settings');

  if (!roles.length || !RoleMap[roles[0] as Role]) return null;

  const role = RoleMap[roles[0] as Role].name;
  const description = RoleMap[roles[0] as Role].description;

  return (
    <span>
      {t(`${role}`)}
      <Tooltip content={t(`${description}`)} side="top" delayDuration={0}>
        <Icon
          type="circle-info"
          variant="light"
          className="text-subtlest ml-sm"
          size="sm"
        />
      </Tooltip>
    </span>
  );
};

UserRole.displayName = 'UserRole';
