import type { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '~/components/Button';

export interface ErrorContentProps extends PropsWithChildren {
  type: 'default' | 'noAccess' | 'notFound';
  fallbackRoute?: {
    href: string;
    label: string;
  };
}

export function ErrorContent({ type, fallbackRoute }: ErrorContentProps) {
  const { t } = useTranslation('errors');

  return (
    <div className="flex flex-col justify-center h-[100svh] bg-default -m-xl">
      <div className="h-full w-full bg-gradient flex marker:flex-col items-center bg-cover bg-no-repeat bg-bottom whitespace-break-spaces">
        <div className="ml-3xl mb-lg" data-testid="error-banner">
          <h1 className="font-header text-display-5xl m-0 leading-none uppercase">
            <Trans
              t={t}
              i18nKey={`${type}.header`}
              components={{ br: <br /> }}
            />
          </h1>
          <p className="text-body-md mt-sm">{t(`${type}.description`)}</p>
          {fallbackRoute && (
            <Button asChild size="large" variant="primary" className="mt-lg">
              <Link to={fallbackRoute.href}>{fallbackRoute.label}</Link>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
ErrorContent.displayName = 'ErrorContent';
