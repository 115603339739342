export type IdentifierLink = {
  name: string;
  url: string;
  displayName?: string;
};

export type ArtistType = {
  id: number;
  uaid: number;
  name: string;
  labels: LabelData[];
  image?: string;
  // isCrossOver suggested variable which can be changed in the future
  isCrossOver?: boolean;
  reports: IdentifierLink[];
  socials: IdentifierLink[];
};

export type ArtistDetails = ArtistType & {
  countryOfOriginISO?: string;
};

export type LabelData = {
  labelId: string;
  name: string;
};

export type ArtistSearchDataResponse = {
  items: ArtistDetailsDataResponse[];
  pagination_offset: {
    offset: number;
    total: number;
  };
};

export type LabelDataResponse = {
  items: {
    label_id: string;
    name: string;
  }[];
  pagination_offset: {
    offset: number;
    total: number;
  };
};

export type ArtistDetailsDataResponse = {
  party_id: number;
  uaid: number;
  image?: {
    sodatone: string;
  };
  name: {
    default_name: string;
    identifiers: {
      identifier_type: string;
      value: string;
    }[];
  };
  labels: {
    label_id: number;
    name: string;
  }[];
  country_of_origin_iso_code?: string;
};

export const IDENTIFIER_TYPES = {
  SODATONE: 'sodatone',
  OPUS: 'opus',
  ARTIST_PACK: 'artist_packs',
  SPOTIFY: 'spotify',
  INSTAGRAM: 'instagram',
  TIKTOK: 'tiktok',
  YOUTUBE: 'youtube',
};
