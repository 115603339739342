import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SETTINGS_ROUTES } from '~settings//config/routes';
import type { UserListItem } from '~settings/api/lib/schemas';
import type { UserListQueryVariables } from '~settings/api/queries/userList';
import { useUserList } from '~settings/api/queries/userList';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar';
import { ViewType } from '~settings/constants';
import { usePagination } from '~settings/hooks/usePagination';

import { UserCard } from '../components/UserCard';
import { useUsersColumns } from './useUsersColumns';

const userEmptyArray: UserListItem[] = [];
const TEMP_HARDCODED_VARIABLES: UserListQueryVariables = {
  skip: 0,
  take: 50,
};

export const Users = () => {
  const { page, handlePageChange, perPage } = usePagination();
  const { isLoading, isError, userList } = useUserList(
    TEMP_HARDCODED_VARIABLES,
  );
  const columns = useUsersColumns();
  const { t } = useTranslation('settings');
  const navigate = useNavigate();
  const [viewType, setViewType] = useState(ViewType.LIST_VIEW);

  const onClick = (row: UserListItem) => {
    navigate(SETTINGS_ROUTES.USER_DETAIL(row.id));
  };

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <>
      <Toolbar
        placeholder={t('searchUsers')}
        viewType={viewType}
        setViewType={setViewType}
      />
      <Pagination
        page={page}
        total={userList?.total ?? 0}
        perPage={perPage}
        entity="users"
        onPageChange={handlePageChange}
      />
      {viewType === ViewType.LIST_VIEW ? (
        <Table
          data={userList?.data ?? userEmptyArray}
          columns={columns}
          onRowClick={onClick}
        />
      ) : (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(26rem,1fr))] gap-md">
          {userList?.data.map((user: UserListItem) => (
            <UserCard key={user.id} user={user} />
          ))}
        </div>
      )}
    </>
  );
};
Users.displayName = 'Users';
