import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import type { LabelListItem } from '~settings/api/lib/schemas';
import { LabelName } from '~settings/components/LabelName';

export const useSublabelsColumns = () => {
  const isExternal = useIsExternalUser();
  const { t } = useTranslation('settings');

  return useMemo(() => {
    const columnHelper = createColumnHelper<LabelListItem>();

    const sublabelsColumns = {
      name: columnHelper.accessor('name', {
        id: 'name',
        header: () => t('label'),
        cell: (cell) => <LabelName name={cell.getValue()} />,
      }),
      labelGroup: columnHelper.accessor((row) => row.labelGroups[0]?.name, {
        id: 'labelGroup',
        header: () => t('labelGroups'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
      usersCount: columnHelper.accessor('usersCount', {
        id: 'usersCount',
        header: () => t('users'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
    };

    const columnsOrder: Array<keyof typeof sublabelsColumns> = isExternal
      ? ['name', 'usersCount']
      : ['name', 'labelGroup', 'usersCount'];

    return columnsOrder.map((key) => sublabelsColumns[key]);
  }, [t, isExternal]);
};
