import { DEFAULT_PAGE_SIZE } from '../components/ArtistTable';
import type { LabelDataResponse } from './types';

export const getLabels = async ({
  name = '',
  limit = DEFAULT_PAGE_SIZE,
  offset = 0,
}: {
  name?: string;
  limit?: number;
  offset?: number;
}) => {
  try {
    const response = await fetch(
      `/api/roster/labels?name=${encodeURIComponent(name)}&limit=${limit}&offset=${offset}`,
    );

    if (!response.ok) {
      throw new Error(`Failed to fetch labels: ${response.statusText}`);
    }

    const data: LabelDataResponse = await response.json();
    return data.items.map((item) => ({
      labelId: item.label_id,
      name: item.name,
    }));
  } catch (error) {
    console.error('Error fetching labels:', error);
    throw error;
  }
};
