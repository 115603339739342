import type { LabelDetail } from '~settings/api/lib/schemas';

const SOCIAL_KEYS = [
  'facebookReference',
  'twitterReference',
  'website',
  'youtubeReference',
  'instagramReference',
] as const;
type SOCIAL_KEYS = (typeof SOCIAL_KEYS)[number];

export interface LabelDetailTransformed extends LabelDetail {
  isSublabel: boolean;
  socials: Array<[SOCIAL_KEYS, string]>;
  adminUsers: LabelDetail['users'];
}

export const transformLabelDetails = (
  labelDetail: LabelDetail,
): LabelDetailTransformed => {
  const socials = SOCIAL_KEYS.reduce(
    (acc: Array<[SOCIAL_KEYS, string]>, key) => {
      if (labelDetail[key]) {
        let value = labelDetail[key];

        if (!/^(https?:)/.test(value)) {
          value = `https://${value}`;
        }
        acc.push([key, value]);
      }

      return acc;
    },
    [],
  );

  return {
    ...labelDetail,
    isSublabel: Boolean(labelDetail.parentId),
    socials,
    adminUsers: labelDetail.users.filter((user) =>
      user.roles.some((role) => role === 'LABEL_ADMIN'),
    ),
  };
};
