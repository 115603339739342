import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { hasSettingsEnabled } from '~/lib/navigationPermissions';
import { Logout } from '~/modules/auth/components/Logout';
import { RefreshAuth } from '~/modules/auth/components/RefreshAuth';
import { SecureRoute } from '~/modules/auth/components/SecureRoute';
import { useUser } from '~/modules/auth/hooks/useUser';
import { AuthRouter } from '~/modules/auth/router';
import { BrandContext } from '~/modules/branding';
import { LinkFarmRouter } from '~/modules/link-farm/router';
import { RemoteRouter } from '~/modules/remotes/router';

import { ErrorPage } from './components/ErrorPage';
import { App as RosterApp } from './modules/artist-roster/App';
import { App as LabelPerfApp } from './modules/label-perf/App';
import { PrimaryLayout } from './modules/navigation/components/PrimaryLayout';
import { App as SettingsApp } from './modules/settings/App';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  const brand = useContext(BrandContext);
  const { features } = useUser();
  const canAccessSettings = hasSettingsEnabled(features);

  const rootRoute =
    brand.name === 'WMG' ? <LinkFarmRouter /> : <RemoteRouter />;

  return (
    <Routes>
      <Route path="login/*" element={<AuthRouter />} />
      <Route element={<SecureRoute />}>
        <Route path="logout" element={<Logout />} />
        <Route path="auth/refresh" element={<RefreshAuth />} />
        <Route element={<PrimaryLayout />}>
          <Route path="*" element={rootRoute} />
          <Route
            path="settings/*"
            element={
              canAccessSettings ? (
                <SettingsApp />
              ) : (
                <ErrorPage type="noAccessPage" />
              )
            }
          />
          <Route path="roster/*" element={<RosterApp />} />
          <Route path="label/*" element={<LabelPerfApp />} />
        </Route>
      </Route>
    </Routes>
  );
};

Router.displayName = 'Router';

export { Router };
