import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { LabelQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import {
  type LabelListResponse,
  LabelListResponseSchema,
} from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';

// TODO: define once Backend pagination, sorting and filtering is supported
export interface LabelListQueryVariables {
  skip: number;
  take: number;
}

// TODO: once pagination is ready, explore https://tanstack.com/query/latest/docs/framework/react/guides/paginated-queries
export function useLabelList<TData = LabelListResponse>(
  variables: LabelListQueryVariables,
  options?: PresetUseQueryOptions<LabelListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: LabelQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await BFFClient.get('labels', {
        headers: getRequestHeaders(session.token),
        searchParams: {
          skip: variables.skip,
          take: variables.take,
        },
      }).json();

      return LabelListResponseSchema.parse(responseData);
    },
    ...options,
  });

  return { labelList: data, ...query };
}
