import type {
  ColumnDef,
  OnChangeFn,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { TableHead } from './components/TableHead';
import { TableRow } from './components/TableRow';
export interface TableProps<TData> {
  data: TData[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];

  // Row specific props
  rowClassName?: string;
  onRowClick?: (row: TData) => void;

  // Sorting specific props
  sort?: {
    onSortingChange: OnChangeFn<SortingState>;
    sorting: SortingState;
  };
}

export const Table = <TData extends { id: string }>({
  data,
  columns,
  rowClassName,
  onRowClick,
  sort,
}: TableProps<TData>) => {
  const { t } = useTranslation('settings');
  const { onSortingChange, sorting } = sort ?? {};

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualSorting: true,
    onSortingChange,
    state: {
      sorting,
    },
    // Enable sorting only if onSortingChange is provided
    enableSorting: !!onSortingChange,
  });

  return (
    <table className="w-full cursor-pointer border-separate border-spacing-0">
      <TableHead headerGroups={table.getHeaderGroups()} />
      <tbody className="text-md">
        {data.length ? (
          table
            .getRowModel()
            .rows.map((row) => (
              <TableRow
                key={row.id}
                row={row}
                className={rowClassName}
                onClick={() => onRowClick?.(row.original)}
              />
            ))
        ) : (
          <tr>
            <td colSpan={columns.length} className="p-md">
              {t('noData')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

Table.displayName = 'Table';
