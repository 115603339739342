import { forwardRef, type HTMLProps } from 'react';

import { Checkbox } from '~/components/Checkbox';
import { mergeClasses } from '~/lib/mergeClasses';

type CheckboxDropdownItemProps = {
  isCheckbox: true;
  checked?: boolean;
  onChange?: (newValue: boolean) => void;
};

type NotCheckboxDropdownItemProps = {
  isCheckbox?: false;
};

type DropdownItemProps = HTMLProps<HTMLDivElement> &
  (CheckboxDropdownItemProps | NotCheckboxDropdownItemProps);

function DropdownItemWithoutRef(
  { isCheckbox, checked, children, className, ...props }: DropdownItemProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  return (
    <div
      role="option"
      tabIndex={0}
      aria-selected={checked}
      className={mergeClasses([
        'flex',
        'px-md',
        'py-sm',
        'text-white',
        'gap-sm',
        'cursor-pointer',
        'transition-colors',
        'duration-200',
        'hover:bg-level-03',
        'focus:bg-level-03',
        'focus:outline-none',
        className,
      ])}
      {...props}
      ref={ref}
    >
      {isCheckbox && (
        <Checkbox checked={Boolean(checked)} value="false" tabIndex={-1} />
      )}
      {children}
    </div>
  );
}

DropdownItemWithoutRef.displayName = 'DropdownItem';

export const DropdownItem = forwardRef(DropdownItemWithoutRef);
