import type React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch } from 'react-router-dom';

import { Separator } from '~/components/Separator';
import { BrandContext } from '~/modules/branding';
import { HoverIconItem } from '~/modules/navigation/components/Sidebar/parts/HoverIconItem';
import { BRANDS } from '~/shared/lib/brands';
import { dataTestIds } from '~/test-utils/dataTestIds';

const SUPPORT_URL = 'https://warnermusic.service-now.com/adahub?id=coop_faq';
const WMG_FEEDBACK_URL =
  'https://docs.google.com/forms/d/e/1FAIpQLSefQ1_QjxKfrYjKxX0fDwgOr8iwGy8ZH0wIAO6Gz6HlU0YvJw/viewform?usp=sf_link';

interface SidebarPopoverContentProps {
  name: string;
  onItemClick?: (event?: React.MouseEvent<HTMLElement>) => void;
}

export const SidebarPopoverContent: React.FC<SidebarPopoverContentProps> = ({
  name,
  onItemClick,
}) => {
  const isVideoManagement = useMatch('/video-management/*');
  const brand = useContext(BrandContext);
  const { t } = useTranslation('navigation', {
    keyPrefix: 'sidebar.popover',
  });

  return (
    <div
      className="
          gap-md
          border-solid
          border-default
          border-px
          border-opacity-10
          flex
          flex-col
          text-white
          justify-between
          content-center
          p-lg
          w-[15rem]
          h-auto
          rounded-xs
          bg-level-02
          mb-lg
          animate-fade
          "
      data-testid={dataTestIds.USER_PROFILE_POPOVER_MENU}
    >
      <p className="text-headline-sm">{name}</p>
      <Separator className="w-full m-0" />
      {brand.name !== BRANDS.WMG ? (
        <>
          <HoverIconItem
            icon="user"
            text={t('profile')}
            to="/my-profile"
            data-testid={dataTestIds.USER_VIEW_PROFILE_BUTTON}
            onClick={onItemClick}
          />
          <HoverIconItem
            icon="circle-question"
            text={t('support')}
            to={SUPPORT_URL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onItemClick}
          />
          {!isVideoManagement && (
            <HoverIconItem
              icon="comment-lines"
              text={t('feedback')}
              to="#feedback_modal"
              data-testid="feedback"
            />
          )}
        </>
      ) : (
        <HoverIconItem
          icon="comment-lines"
          text={t('feedback')}
          to={WMG_FEEDBACK_URL}
          target="_blank"
          data-testid="feedback"
        />
      )}

      <Separator className="w-full m-0" />
      <HoverIconItem
        icon="right-from-bracket"
        text={t('signOut')}
        to="/logout"
        data-testid={dataTestIds.USER_SIGN_OUT_BUTTON}
      />
    </div>
  );
};

SidebarPopoverContent.displayName = 'SidebarPopoverContent';
