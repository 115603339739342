import { createColumnHelper } from '@tanstack/react-table';

import { Icon, Tag, Tooltip } from '~/components';
import { Avatar } from '~/components/Avatar';
import { Instagram, Spotify, Tiktok, Youtube } from '~/components/SocialIcons';
import { ArtistCrossOverIcon } from '~/modules/artist-roster/components/ArtistCrossOverIcon.tsx';
import type { ArtistType } from '~/modules/artist-roster/lib/types';
import { instance } from '~/modules/monitoring/amplitude.ts';

const columnHelper = createColumnHelper<ArtistType>();

export const socialIconMap: Record<string, React.ElementType> = {
  spotify: Spotify,
  instagram: Instagram,
  tiktok: Tiktok,
  youtube: Youtube,
};

const dataToolsOrder = ['Opus', 'Artist Pack', 'Sodatone'];

export type ArtistTableMeta = {
  filtersCount: number;
  filtersApplied: boolean;
  crossoverFilterApplied: boolean;
};

export const columns = [
  columnHelper.accessor('name', {
    id: 'name',
    header: () => 'Name',
    cell: ({ row }) => (
      <div className={'flex items-center gap-sm'}>
        {row.original.image ? (
          <img
            src={row.original.image}
            alt={row.original.name}
            className={'w-2xl h-2xl rounded-full'}
          />
        ) : (
          <Avatar name={row.original.name} variant={'base'} />
        )}
        <div className={'font-bold text-headline-sm'}>{row.original.name}</div>
        {row.original.isCrossOver && <ArtistCrossOverIcon />}
      </div>
    ),
  }),
  columnHelper.accessor('labels', {
    id: 'labels',
    header: () => 'Label(s)',
    cell: ({ row }) =>
      !!row.original.labels.length && (
        <div className={'flex gap-md'}>
          <span className="text-body-md font-normal">
            {row.original.labels[0].name}
          </span>
          {row.original.labels.length > 1 && (
            <Tooltip
              content={row.original.labels
                .slice(1)
                .map((label) => label.name)
                .join(', ')}
              delayDuration={0}
              side="top"
              sideOffset={8}
              triggerAsChild
              size={'sm'}
            >
              <span className="cursor-pointer border-b-px border-white text-body-sm font-semibold">
                {`+${row.original.labels.length - 1} more`}
              </span>
            </Tooltip>
          )}
        </div>
      ),
  }),
  columnHelper.accessor('reports', {
    id: 'reports',
    header: () => 'Data tools',
    cell: ({ row, table }) => {
      const reports = [...(row.original?.reports ?? [])];
      reports.sort(
        (a, b) =>
          dataToolsOrder.indexOf(a.displayName ?? a.name) -
          dataToolsOrder.indexOf(b.displayName ?? b.name),
      );
      return (
        <div className="flex flex-row gap-sm">
          {reports.map((q) => (
            <a
              key={q.name}
              href={q.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
                const meta = table.options?.meta as ArtistTableMeta | undefined;
                instance.track('roster_table_artist_interaction', {
                  artist_id: row.original.id,
                  artist_name: row.original.name,
                  click_type: q.name,
                  roster_label_filters_count: meta?.filtersCount,
                  roster_crossover_filter_applied: false,
                  roster_label_filters_applied: meta?.filtersApplied,
                });
              }}
            >
              <Tag variant={'secondary'}>
                {q.displayName ?? q.name}
                <Icon
                  type="external-link"
                  size="sm"
                  variant="regular"
                  role="presentation"
                  aria-hidden
                  aria-label="external-url-icon"
                  className="ml-sm text-white"
                />
              </Tag>
            </a>
          ))}
        </div>
      );
    },
  }),
  columnHelper.accessor('socials', {
    id: 'socials',
    header: () => 'Socials',
    cell: ({ row }) => (
      <div className={'flex gap-lg items-center'}>
        {row.original?.socials?.map((s) => {
          const SocialIcon = socialIconMap[s.name];
          return (
            <a
              key={`${s.name}_${s.url}`}
              className={'w-[20px]'}
              href={s.url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <span className="sr-only">{s.name}</span>
              <SocialIcon className="text-hover hover:text-white transition" />
            </a>
          );
        })}
      </div>
    ),
  }),
];
