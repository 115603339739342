/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar } from '~/components/Avatar';

import { Icon, Separator, Tooltip } from '../../../components';
import type { Role, UserListItem } from '../api/lib/schemas';
import { RoleMap } from '../constants/RoleMap';
import { GroupInfo } from './GroupInfo';

const UserCard = ({ user }: { user: UserListItem }) => {
  const { t } = useTranslation('settings');
  const { fullName, email, roles, labels, id } = user;

  if (!RoleMap[roles[0] as Role]) {
    return null;
  }

  const role = RoleMap[roles[0] as Role].name;
  const description = RoleMap[roles[0] as Role].description;

  return (
    <div className="w-full rounded-sm border border-emphasis-subtle">
      <div className="bg-primarylight bg-cover bg-center pt-md px-md">
        <div className="flex justify-between">
          <Avatar name={fullName} variant="primary" size="lg" />
          <div>
            <span className="border border-emphasis-subtle rounded-xs text-strong inline-flex items-baseline bg-primary-disabled px-sm py-xs text-caption-md gap-xs">
              {role}
              <Tooltip
                content={t(`${description}`)}
                side="top"
                delayDuration={0}
              >
                <Icon type="circle-info" variant="light" size="sm" />
              </Tooltip>
            </span>
          </div>
        </div>
        <div className="text-body-lg py-sm">{fullName}</div>
      </div>
      <div className="px-md pb-md">
        <div className="flex items-center gap-xs pb-md text-body-xs opacity-80">
          <span>{email}</span>
        </div>

        <Separator />

        <div className="flex justify-between items-center pt-md">
          <div className="flex items-center opacity-60">
            <Icon type="list-music" variant="light" size="sm" />
            <div className="text-body-xs ml-sm">
              <GroupInfo groups={labels.map((label) => label.name)} />
            </div>
          </div>

          <Link to={`/settings/users/${id}`} className="text-body-xs">
            {t('goToDetails')}
            <Icon
              type="arrow-up-right-from-square"
              variant="light"
              className="text-subtlest ml-sm"
              size="sm"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

UserCard.displayName = 'UserCard';

export { UserCard };
