import { useState } from 'react';

import { Icon } from '~/components';
import { mergeClasses } from '~/lib/mergeClasses';
import { instance } from '~/modules/monitoring/amplitude.ts';

import { exportArtistsCSV } from '../../lib/artists';

interface ArtistExportProps {
  search?: string;
  labels?: string[];
  className?: string;
}

const ArtistExport = ({
  search = '',
  className = '',
  labels = [],
}: ArtistExportProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const onExportClick = async () => {
    setIsLoading(true);
    instance.track('roster_exported', {
      roster_label_filters_count: labels.length,
      roster_label_filters_applied: labels.length > 0,
      roster_crossover_filter_applied: false, // TODO: Update this when crossover filters are added
    });
    await exportArtistsCSV({ search, labels });
    setIsLoading(false);
  };

  return (
    <button
      className={mergeClasses(
        `flex justify-center items-center gap-sm underline font-semibold ${isLoading ? 'text-disabled' : 'text-white'}`,
        className,
      )}
      onClick={onExportClick}
      disabled={isLoading}
    >
      {isLoading ? `Exporting` : `Export`}
      <Icon type="file-export" variant="regular" size="sm" />
    </button>
  );
};

ArtistExport.displayName = 'ArtistExport';

export { ArtistExport };
