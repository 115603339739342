import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { LabelListItem } from '~settings/api/lib/schemas';
import type { LabelListQueryVariables } from '~settings/api/queries/labelList';
import { useLabelList } from '~settings/api/queries/labelList';
import { LabelCard } from '~settings/components/LabelCard';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { ViewType } from '~settings/constants';
import { usePagination } from '~settings/hooks/usePagination';

import { useLabelsColumns } from './useLabelsColumns';

const emptyLabels: LabelListItem[] = [];
const TEMP_HARDCODED_VARIABLES: LabelListQueryVariables = {
  skip: 0,
  take: 50,
};

export const Labels = () => {
  const { isLoading, isError, labelList } = useLabelList(
    TEMP_HARDCODED_VARIABLES,
  );
  const { t } = useTranslation('settings');
  const { page, handlePageChange, perPage } = usePagination();
  const [viewType, setViewType] = useState(ViewType.LIST_VIEW);
  const columns = useLabelsColumns();

  const navigate = useNavigate();
  const onClick = (row: LabelListItem) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <>
      <Toolbar
        placeholder={t('searchLabels')}
        viewType={viewType}
        setViewType={setViewType}
      />
      <Pagination
        page={page}
        total={labelList?.total ?? 0}
        perPage={perPage}
        entity="labels"
        onPageChange={handlePageChange}
      />
      {viewType === ViewType.LIST_VIEW ? (
        <Table
          data={labelList?.data ?? emptyLabels}
          columns={columns}
          onRowClick={onClick}
        />
      ) : (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(26rem,1fr))] gap-md">
          {labelList?.data.map((label: LabelListItem) => (
            <LabelCard key={label.id} label={label} />
          ))}
        </div>
      )}
    </>
  );
};
Labels.displayName = 'Labels';
