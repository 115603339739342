import type { HeaderGroup } from '@tanstack/react-table';
import type { HTMLProps } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

import { TableHeadCell } from '../TableHeadCell';

type TableHeadProps<TData> = HTMLProps<HTMLTableSectionElement> & {
  headerGroups: HeaderGroup<TData>[];
};

export function TableHead<TData>({
  headerGroups,
  className,
  ...props
}: TableHeadProps<TData>) {
  return (
    <thead
      className={mergeClasses(
        'text-sm',
        'text-left',
        'leading-tight',
        className,
      )}
      {...props}
    >
      {headerGroups.map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header) => (
            <TableHeadCell key={header.id} header={header} />
          ))}
        </tr>
      ))}
    </thead>
  );
}

TableHead.displayName = 'TableHead';
