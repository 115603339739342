import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { Label, LabelListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { ViewType } from '~settings/constants';
import { usePagination } from '~settings/hooks/usePagination';

import { useUserLabelsColumns } from './useUserLabelsColumns';

interface Props {
  labels: Label[];
}

const emptyLabels: LabelListItem[] = [];

export const UserLabels = ({ labels }: Props) => {
  const columns = useUserLabelsColumns();
  const { t } = useTranslation('settings');
  const { page, handlePageChange, perPage } = usePagination();
  const [viewType, setViewType] = useState(ViewType.LIST_VIEW);

  const navigate = useNavigate();
  const onClick = (row: Label) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };

  return (
    <>
      <HeaderSmall>{t('labels')}</HeaderSmall>
      <Toolbar
        placeholder={t('searchLabels')}
        viewType={viewType}
        setViewType={setViewType}
      />
      <Pagination
        page={page}
        total={labels.length}
        perPage={perPage}
        entity="labels"
        onPageChange={handlePageChange}
      />
      <Table
        data={labels ?? emptyLabels}
        columns={columns}
        rowClassName="odd:bg-level-03"
        onRowClick={onClick}
      />
    </>
  );
};

UserLabels.displayName = 'UserLabels';
