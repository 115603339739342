import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components/Icon';
import { mergeClasses } from '~/lib/mergeClasses';
import type { VariantProps } from '~/lib/variants';
import { variants } from '~/lib/variants';

import { useDropdownContext } from '../../contexts/DropdownContext';
import { useTriggerEventHandlers } from '../../hooks/useTriggerEventHandlers';
import { DropdownTag } from '../DropdownTag';

const trigger = variants({
  base: [
    'w-full',
    'h-auto',
    'outline',
    'outline-1',
    'outline-emphasis-subtle',
    'outline-offset-[-1px]',
    'focus:ring-none',
    'text-body-sm',
    'rounded-xs',
    'py-sm',
    'pl-md',
    'pr-xl',
    'bg-emphasis-default',
    'text-strong',
    'cursor-pointer',
    'flex',
    'flex-wrap',
    'items-center',
    'gap-sm',
    'relative',
  ],
  variants: {
    state: {
      default: ['focus:outline-2', 'focus:outline-secondary-default'],
      error: ['outline', 'outline-error-default', 'outline-2'],
      warning: ['outline', 'outline-warning-default', 'outline-2'],
      success: ['outline', 'outline-success-default', 'outline-2'],
    },
    size: {
      xlarge: ['py-md'],
      large: ['min-h-2xl'],
    },
    open: {
      true: ['outline-2', 'outline-secondary-default'],
    },
    disabled: {
      true: [
        'outline',
        'outline-disabled',
        'cursor-not-allowed',
        'pointer-events-none',
        'border-alpha-stronger-emphasis',
      ],
    },
  },
});

const chevron = variants({
  base: [
    'mx-sm',
    'transition-transform',
    'duration-200',
    'absolute',
    'right-sm',
  ],
  variants: {
    state: {
      default: ['transform', 'rotate-0'],
      open: ['transform', 'rotate-180'],
    },
  },
});

type DropdownTriggerProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof trigger> & { placeholder?: string };

function DropdownTriggerWithoutRef(
  {
    size = 'large',
    state = 'default',
    disabled,
    placeholder,
    className,
    ...props
  }: DropdownTriggerProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { t } = useTranslation('components', { keyPrefix: 'dropdown' });
  const { multiselect, displaySelectedOptions, isOpen, selected, onSelect } =
    useDropdownContext();
  const triggerHandlers = useTriggerEventHandlers();

  return (
    <div
      className={mergeClasses([
        trigger({ size, state, disabled, open: isOpen }),
        className,
      ])}
      aria-disabled={disabled}
      aria-expanded={isOpen}
      // Radix handles the aria-controls attribute
      // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
      role="combobox"
      tabIndex={0}
      {...props}
      {...triggerHandlers}
      ref={ref}
    >
      {/* Multiselect with tags that can remove selection */}
      {multiselect &&
        displaySelectedOptions &&
        selected.map(({ value, label }) => (
          <DropdownTag key={value} onRemove={() => onSelect(value)}>
            {label}
          </DropdownTag>
        ))}

      {/* Multiselect with single tag without interactivity */}
      {multiselect && !displaySelectedOptions && selected.length > 0 && (
        <DropdownTag>
          {t('numberSelected', { count: selected.length })}
        </DropdownTag>
      )}

      {/* Single select label */}
      {!multiselect && selected[0]?.label}

      {/* Placeholder */}
      {selected.length === 0 && (
        <span className="text-subtle text-body-sm">{placeholder}</span>
      )}

      <Icon
        className={chevron({ state: isOpen ? 'open' : 'default' })}
        size="md"
        type="chevron-down"
      />
    </div>
  );
}

DropdownTriggerWithoutRef.displayName = 'DropdownTriggerWithoutRef';

export const DropdownTrigger = forwardRef(DropdownTriggerWithoutRef);

DropdownTrigger.displayName = 'DropdownTrigger';
