import { useUser } from '~/modules/auth/hooks/useUser';
import type { HasPermissionFunction } from '~/modules/remotes/types';

export enum VIEWS {
  LABEL_GROUPS_LIST,
}

export const PAGES_TO_PERMISSIONS = {
  [VIEWS.LABEL_GROUPS_LIST]: (hasPermission: HasPermissionFunction) =>
    hasPermission('LABEL_GROUP.CREATE') ||
    hasPermission('LABEL_GROUP.EDIT') ||
    hasPermission('LABEL_GROUP.DELETE'),
};

export const useCanAccessView = (view: VIEWS) => {
  const { hasPermission } = useUser();
  const validatePermissions = PAGES_TO_PERMISSIONS[view];

  return validatePermissions(hasPermission);
};
