import { Icon } from '~/components/Icon/Icon';
import { cn } from '~/lib/mergeClasses';
interface PageButtonProps {
  onPrevious?: () => void;
  onNext?: () => void;
  currentPage: number;
  totalPages: number;
}

export function PageButtons({
  onPrevious,
  onNext,
  currentPage,
  totalPages,
}: PageButtonProps) {
  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  return (
    <div className="flex space-x-xs bg-level-03 rounded-sm">
      {['previous', 'next'].map((position) => {
        const isPrevious = position === 'previous';
        const isDisabled = isPrevious ? isPreviousDisabled : isNextDisabled;
        const onClick = isPrevious ? onPrevious : onNext;
        const iconType = 'angle-' + (isPrevious ? 'left' : 'right');
        const padding = isPrevious ? 'pl-xs' : 'pr-xs';
        const ariaLabel = `pagination ${position} arrow`;
        const rounded = isPrevious ? 'rounded-s-sm' : 'rounded-e-sm';

        return (
          <div
            role="button"
            aria-label={`${position} page button`}
            key={position}
            className={cn(
              `flex justify-center items-center transition-colors duration-100 ${!isDisabled ? 'hover:bg-primary-hover' : ''}`,
              padding,
              rounded,
            )}
          >
            <button
              aria-label={ariaLabel}
              className="flex p-sm"
              onClick={onClick}
              disabled={isDisabled}
            >
              <Icon
                className={cn(
                  '',
                  isDisabled ? 'text-disabled' : 'text-default',
                )}
                type={iconType}
                size="md"
                data-testid={`pagination-${position}-arrow`}
              />
            </button>
          </div>
        );
      })}
    </div>
  );
}

PageButtons.displayName = 'PageButtons';
