/* eslint-disable react/prop-types */
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Avatar } from '~/components/Avatar';

import { Icon, Separator } from '../../../components';
import type { LabelListItem } from '../api/lib/schemas';

const enum LabelType {
  PARENT_LABEL = 'Parent label',
  SUBLABEL = 'Sublabel',
}

const LabelCard = ({ label }: { label: LabelListItem }) => {
  const { t } = useTranslation('settings');
  const { isParentLabel, name, sublabelsCount, usersCount, id } = label;

  return (
    <div className="w-full rounded-sm border border-emphasis-subtle">
      <div className="bg-secondarylight bg-cover bg-center pt-md px-md">
        <div className="flex justify-between">
          <Avatar name={name} variant="primary" size="lg" shape="square" />
          <div>
            <span className="border border-emphasis-subtle rounded-xs text-strong inline-flex items-baseline bg-primary-disabled px-sm py-xs text-caption-md gap-xs">
              {isParentLabel ? LabelType.PARENT_LABEL : LabelType.SUBLABEL}
            </span>
          </div>
        </div>
        <div className="text-body-lg py-sm">{name}</div>
      </div>
      <div className="px-md pb-md">
        <div className="flex items-center gap-xs pb-md text-body-xs opacity-80">
          {isParentLabel && (
            <>
              <span>{t('sublabelCount', { count: sublabelsCount })}</span>
              <span>•</span>
            </>
          )}
          <span>{t('userCount', { count: usersCount })}</span>
        </div>

        <Separator />

        <div className="flex justify-end items-center pt-md">
          <Link to={`/settings/labels/${id}`} className="text-body-xs">
            {t('goToDetails')}
            <Icon
              type="arrow-up-right-from-square"
              variant="light"
              className="text-subtlest ml-sm"
              size="sm"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

LabelCard.displayName = 'LabelCard';

export { LabelCard };
