interface UserInfoProps {
  firstName: string;
  lastName: string;
  fullName: string;
}

export const UserInfo = ({ firstName, lastName, fullName }: UserInfoProps) => {
  const initials = firstName[0]?.toUpperCase() + lastName[0]?.toUpperCase();

  return (
    <div className="flex items-center">
      <span className="mr-sm w-lg h-lg basis-4 rounded-full leading-6 bg-level-05 text-body-xs text-center">
        {initials}
      </span>
      <span className="text-default">{fullName}</span>
    </div>
  );
};

UserInfo.displayName = 'UserInfo';
