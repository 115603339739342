import { useMemo } from 'react';

import { PageButtons } from '~/modules/pagination/PageButtons';

import { usePaginationMessage } from '../../hooks/usePagination';
type PaginationEntity = 'sublabels' | 'labels' | 'users';

export interface PaginationProps {
  page: number;
  total: number;
  entity: PaginationEntity;
  perPage: number;
  onPageChange: (page: number) => void;
}

export const Pagination = ({
  page,
  total,
  entity,
  perPage = 10,
  onPageChange,
}: PaginationProps) => {
  const totalPages = useMemo(
    () => Math.ceil(total / perPage),
    [total, perPage],
  );

  const message = usePaginationMessage(page, perPage, total, entity);

  if (total === 0) {
    return null;
  }

  return (
    <div className="inline-flex items-center justify-start w-full">
      <div id="right" className="ml-auto">
        <div className="inline-flex items-center">
          <span className="text-disabled text-nowrap pr-sm font-semibold text-body-sm">
            {message}
          </span>
          <PageButtons
            onNext={() => onPageChange(page + 1)}
            onPrevious={() => onPageChange(page - 1)}
            currentPage={page}
            totalPages={totalPages}
          />
        </div>
      </div>
    </div>
  );
};

Pagination.displayName = 'Pagination';
