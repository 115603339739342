import type { Role } from '../api/lib/schemas';

export interface RoleInfo {
  name: string;
  description: string;
}

export const RoleMap: Record<Role, RoleInfo> = {
  LABEL_ADMIN: { name: 'Label Admin', description: 'labelAdmin' },
  RELEASE_COORDINATOR: {
    name: 'Release Coordinator',
    description: 'releaseCoordinator',
  },
  VIDEO_COORDINATOR: {
    name: 'Video Coordinator',
    description: 'videoCoordinator',
  },
  OPS_ADMIN: {
    name: 'Ops Admin',
    description: 'opsAdmin',
  },
};
