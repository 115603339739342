export function Youtube({ className }: { className?: string }) {
  return (
    <svg
      role="img"
      viewBox="0 0 20 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.1758 3.64453C19.5977 5.12109 19.5977 8.28516 19.5977 8.28516C19.5977 8.28516 19.5977 11.4141 19.1758 12.9258C18.9648 13.7695 18.2969 14.4023 17.4883 14.6133C15.9766 15 10 15 10 15C10 15 3.98828 15 2.47656 14.6133C1.66797 14.4023 1 13.7695 0.789062 12.9258C0.367188 11.4141 0.367188 8.28516 0.367188 8.28516C0.367188 8.28516 0.367188 5.12109 0.789062 3.64453C1 2.80078 1.66797 2.13281 2.47656 1.92188C3.98828 1.5 10 1.5 10 1.5C10 1.5 15.9766 1.5 17.4883 1.92188C18.2969 2.13281 18.9648 2.80078 19.1758 3.64453ZM8.03125 11.1328L13.0234 8.28516L8.03125 5.4375V11.1328Z" />
    </svg>
  );
}
Youtube.displayName = 'Youtube';
