import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { LabelGroupListItem } from '~settings/api/lib/schemas';

export const useLabelGroupsColumns = () => {
  const { t } = useTranslation('settings');

  return useMemo(() => {
    const columnHelper = createColumnHelper<LabelGroupListItem>();

    const usersColumns = {
      name: columnHelper.accessor((row) => row.name, {
        id: 'name',
        cell: (cell) => cell.getValue(),
        header: () => t('name'),
      }),
      numberOfLabels: columnHelper.accessor((row) => row.numberOfLabels, {
        id: 'labels',
        cell: (cell) => {
          return cell.getValue() ? cell.getValue() : '-';
        },
        header: () => t('labels'),
      }),
      numberOfUsers: columnHelper.accessor((row) => row.numberOfUsers, {
        id: 'users',
        cell: (cell) => {
          return cell.getValue() ? cell.getValue() : '-';
        },
        header: () => t('users'),
      }),
    };

    return Object.values(usersColumns);
  }, [t]);
};
