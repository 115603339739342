import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { LabelListItem } from '~settings/api/lib/schemas';
import { LabelCard } from '~settings/components/LabelCard';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { ViewType } from '~settings/constants';
import { usePagination } from '~settings/hooks/usePagination';
import { useSublabelsColumns } from '~settings/LabelDetails/hooks/useSublabelsColumns';

export const Sublabels = ({ sublabels }: { sublabels: LabelListItem[] }) => {
  const columns = useSublabelsColumns();
  const [viewType, setViewType] = useState(ViewType.LIST_VIEW);

  const { t } = useTranslation('settings');
  const { page, handlePageChange, perPage } = usePagination();
  const navigate = useNavigate();
  const onClick = (row: LabelListItem) => {
    navigate(SETTINGS_ROUTES.LABEL_DETAIL(row.id));
  };
  const isListView = viewType === ViewType.LIST_VIEW;

  return (
    <>
      <Toolbar
        placeholder={t('searchLabels')}
        viewType={viewType}
        setViewType={setViewType}
      />
      <Pagination
        page={page}
        total={sublabels.length}
        perPage={perPage}
        entity="sublabels"
        onPageChange={handlePageChange}
      />
      {isListView ? (
        <Table
          data={sublabels}
          columns={columns}
          rowClassName="odd:bg-level-03"
          onRowClick={onClick}
        />
      ) : (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(26rem,1fr))] gap-md">
          {sublabels.map((sublabel: LabelListItem) => (
            <LabelCard key={sublabel.id} label={sublabel} />
          ))}
        </div>
      )}
    </>
  );
};

Sublabels.displayName = 'Sublabels';
