import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import type { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import type { PropsWithChildren } from 'react';
import { useContext, useMemo } from 'react';

import { BrandContext } from '~/modules/branding';

import { getOktaAuth } from '../lib/okta-auth';

/**
 * @see https://github.com/okta/okta-react?tab=readme-ov-file#restoreoriginaluri
 */
const restoreOriginalUri: RestoreOriginalUriFunction = (
  _oktaAuth,
  originalUri,
) => {
  const relativeURI = toRelativeUrl(originalUri || '/', window.location.origin);
  if (relativeURI !== window.location.pathname) {
    // This will unintentionally cancel the first request to /api/auth and
    // /api/structured_permissions, due to Classic Co-Op not being able to handle
    // re-renders appropriately in the Release Management flows. It should use
    // react-router-dom@navigate, but window.location.replace is the only
    // viable workaround for now.
    // TODO: Update this to use react-router-dom@navigate
    window.location.replace(relativeURI);
  }
};

/**
 * Connects the Okta Security provider.
 */
const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const brand = useContext(BrandContext);
  const oktaAuth = useMemo(() => getOktaAuth(brand), [brand]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

AuthenticationProvider.displayName = 'AuthenticationProvider';

export { AuthenticationProvider };
