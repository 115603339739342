import * as RadixTooltip from '@radix-ui/react-tooltip';
import type { ReactNode } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';
import { variants } from '~/lib/variants';

type TooltipSize = 'md' | 'sm';

type TooltipProps = {
  triggerAsChild?: boolean;
  content: ReactNode;
  children: ReactNode;
  side?: RadixTooltip.TooltipContentProps['side'];
  delayDuration?: number;
  enabled?: boolean;
  size?: TooltipSize;
  sideOffset?: number;
  align?: RadixTooltip.TooltipContentProps['align'];
};

const tooltip = variants({
  base: [
    'bg-default',
    'text-default',
    'animate-fade',
    'z-50',
    'shadow',
    'border',
  ],
  variants: {
    size: {
      md: ['p-md', 'text-body-sm', 'rounded-sm', 'max-w-xs'],
      sm: ['py-xs', 'px-sm', 'text-body-xs', 'rounded-xs'],
    },
  },
});

const Tooltip = ({
  content,
  children,
  side,
  size = 'md',
  delayDuration,
  enabled = true,
  triggerAsChild = false,
  sideOffset,
  align,
}: TooltipProps) => {
  const sizeClasses = tooltip({ size });

  return (
    <RadixTooltip.Provider>
      <RadixTooltip.Root delayDuration={delayDuration}>
        {enabled ? (
          <RadixTooltip.Trigger asChild={triggerAsChild}>
            {children}
          </RadixTooltip.Trigger>
        ) : (
          <>{children}</>
        )}
        <RadixTooltip.Portal>
          <RadixTooltip.Content
            className={mergeClasses(sizeClasses)}
            side={side}
            sideOffset={sideOffset}
            align={align}
          >
            {content}
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

Tooltip.displayName = 'Tooltip';

export { Tooltip };
