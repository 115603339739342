import type { HTMLProps, ReactNode } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

interface LabelProps extends HTMLProps<HTMLLabelElement> {
  children: ReactNode;
  disabled?: boolean;
}

const Label = ({
  children,
  disabled = false,
  className,
  ...props
}: LabelProps) => {
  return (
    <label
      {...props}
      className={mergeClasses(
        'text-body-sm',
        disabled && 'text-disabled',
        !disabled && 'text-default',
        className,
      )}
    >
      {children}
    </label>
  );
};

Label.displayName = 'Label';

export { Label };
