import { useTranslation } from 'react-i18next';

import { Icon, Tag, Tooltip } from '~/components';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';

export function Header() {
  const isExternal = useIsExternalUser();
  const { t } = useTranslation('settings');

  const content = (
    <>
      <div className="py-sm px-sm text-headline-md leading-tight">
        {t('betaInfo.title')}
      </div>
      <div className="py-sm px-sm">{t('betaInfo.description')}</div>
    </>
  );

  return (
    <header className="flex items-center gap-md">
      <Icon type="gear" size="lg" />
      <h1 className="py-sm text-display-sm font-semibold">{t('title')}</h1>
      {!isExternal && (
        <Tag variant="secondary" size="lg" data-testid="tag-component">
          <Tooltip content={content} side="top">
            {t('betaInfo.tooltipTrigger')}
            <Icon
              type="circle-info"
              variant="light"
              className="text-subtlest ml-xs"
              size="md"
            />
          </Tooltip>
        </Tag>
      )}
    </header>
  );
}

Header.displayName = 'Header';
