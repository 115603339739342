import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { columns } from '~/modules/artist-roster/components/ArtistColumns';
import { ArtistDataTable } from '~/modules/artist-roster/components/ArtistDataTable';
import { ArtistSearchBar } from '~/modules/artist-roster/components/ArtistSearchBar/ArtistSearchBar';
import { getArtists } from '~/modules/artist-roster/lib/artists';
import { instance } from '~/modules/monitoring/amplitude';
import { PageButtons } from '~/modules/pagination/PageButtons';

import { ArtistExport } from './ArtistExport/ArtistExport';
import { ArtistAppliedFilterList } from './ArtistFilter/ArtistAppliedFilterList';
import { ArtistFilterButton } from './ArtistFilter/ArtistFilterButton';
import type { ArtistFiltersType } from './ArtistFilter/ArtistFiltersType';
import { DEFAULT_ARTIST_FILTERS } from './ArtistFilter/ArtistFiltersType';
import { Header } from './Header';

export const DEFAULT_PAGE_SIZE = 20;

export function ArtistTable() {
  const { t } = useTranslation('roster');
  const [searchParams, setSearchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams.get('search') || '');
  const page = searchParams.get('page');
  const [pageNumber, setPageNumber] = useState(page && +page ? +page : 1);
  const [totalArtists, setTotalArtists] = useState(0);
  const [filters, setFilters] = useState<ArtistFiltersType>(
    DEFAULT_ARTIST_FILTERS,
  );

  const {
    data,
    error,
    isLoading: isLoadingArtists,
  } = useQuery({
    queryKey: [
      'artist-roster',
      search,
      pageNumber,
      filters.labels,
      filters.isCrossover,
    ],
    queryFn: () =>
      getArtists({
        search,
        offset: (pageNumber - 1) * DEFAULT_PAGE_SIZE,
        limit: DEFAULT_PAGE_SIZE,
        labels: filters.labels.map((label) => label.labelId),
        isCrossover: filters.isCrossover,
      }),
  });

  const totalPages = Math.ceil(totalArtists / DEFAULT_PAGE_SIZE);

  const onSubmitSearch = useCallback(
    (value: string) => {
      instance.track('roster_artist_search', {
        roster_label_filters_count: filters.labels.length,
        roster_label_filters_applied: filters.labels.length > 0,
        roster_crossover_filter_applied: filters.isCrossover,
        search_term: value,
      });
      setSearch(value);
      setPageNumber(1);
    },
    [filters, setSearch, setPageNumber],
  );

  useEffect(() => {
    if (!isLoadingArtists) {
      setTotalArtists(data?.pagination?.total ?? 0);
    }
  }, [isLoadingArtists, data]);

  useEffect(() => {
    const params = new URLSearchParams('');
    if (search) {
      params.set('search', search);
    }
    if (pageNumber > 1) {
      params.set('page', pageNumber.toString());
    }
    setSearchParams(params);
  }, [search, pageNumber, setSearchParams]);

  const onRemoveFilter = useCallback(
    (value: string) => {
      const newLabelFilters = filters.labels.filter(
        (labelData) => labelData.labelId !== value,
      );
      setFilters({
        labels: newLabelFilters,
        isCrossover: filters.isCrossover,
      });
    },
    [filters, setFilters],
  );

  const artistTableMeta = {
    filtersCount: filters.labels.length,
    filtersApplied: filters.labels.length > 0,
    crossoverFilterApplied: filters.isCrossover,
  };

  const onPaginationAmplitudeTrack = useCallback(
    (directionToNextPage: boolean) => {
      instance.track('roster_pagination_click', {
        roster_label_filters_count: filters.labels.length,
        roster_crossover_filter_applied: filters.isCrossover,
        roster_label_filters_applied: filters.labels.length > 0,
        direction: directionToNextPage ? 'next_page' : 'previous_page',
      });
    },
    [filters],
  );

  const onFilterClick = useCallback(() => {
    instance.track('roster_filters_click', {
      roster_label_filters_count: filters.labels.length,
      roster_crossover_filter_applied: filters.isCrossover,
      roster_label_filters_applied: filters.labels.length > 0,
    });
  }, [filters]);

  const onFilterApplied = useCallback(
    ({ labels, isCrossover }: ArtistFiltersType) => {
      setFilters({
        labels,
        isCrossover,
      });
      instance.track('roster_label_filters_applied', {
        roster_label_filters_count: labels.length,
        roster_crossover_filter_applied: isCrossover,
        roster_label_filters_applied: labels.length > 0,
      });
    },
    [setFilters],
  );

  if (error) {
    // TODO Get proper UX for errors
    return (
      <div role="alert" className={'text-error-default'}>
        {error.message}
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-between items-end py-lg">
        <Header />
        {!isLoadingArtists && !!totalArtists && (
          <ArtistExport
            search={search}
            labels={filters.labels.map((label) => label.labelId)}
          />
        )}
      </div>
      <div className="flex gap-sm" role="search">
        <ArtistFilterButton
          selectedFilters={filters}
          onApplyFilters={onFilterApplied}
          onClick={onFilterClick}
          aria-label="Apply filters to the artist table"
        />
        <ArtistSearchBar
          initialValue={search}
          placeholder="Search for an artist"
          onSubmit={onSubmitSearch}
          aria-label="Search artists by name"
        />
      </div>
      {filters.labels.length > 0 && (
        <ArtistAppliedFilterList
          appliedFilters={filters.labels.map((labelData) => {
            return {
              category: t('Label'),
              value: labelData.labelId,
              displayName: labelData.name,
            };
          })}
          onRemoveFilter={onRemoveFilter}
        />
      )}
      <div className="inline-flex items-center justify-between w-full my-md min-h-xl">
        <div className="flex gap-xs">
          {t('header.subtitle')}
          {!isLoadingArtists && <p className="text-subtle">({totalArtists})</p>}
        </div>

        {totalArtists > DEFAULT_PAGE_SIZE && (
          <div id="right">
            <div className="inline-flex items-center">
              <span
                className="text-disabled text-nowrap pr-sm font-semibold text-body-sm"
                aria-live="polite"
              >
                {t('header.pagination.label', {
                  start: (pageNumber - 1) * 20 + 1,
                  end: Math.min(pageNumber * 20, totalArtists),
                  total: totalArtists,
                  type: t('header.pagination.type'),
                })}
              </span>
              <PageButtons
                currentPage={pageNumber}
                totalPages={totalPages}
                onNext={() => {
                  setPageNumber(Math.min(pageNumber + 1, totalPages));
                  onPaginationAmplitudeTrack(true);
                }}
                onPrevious={() => {
                  setPageNumber(Math.max(pageNumber - 1, 1));
                  onPaginationAmplitudeTrack(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <ArtistDataTable
        columns={columns}
        meta={artistTableMeta}
        data={data?.items ?? []}
        isLoading={isLoadingArtists}
      />
    </>
  );
}

ArtistTable.displayName = 'ArtistTable';
