import { Route, Routes } from 'react-router-dom';

import { ErrorPage } from '~/components/ErrorPage';
import { useCanAccessView, VIEWS } from '~settings/hooks/useCanAccessView';
import { UserDetails } from '~settings/UserDetails';

import { TabsPagesLayout } from './components';
import { LabelDetails } from './LabelDetails';
import { LabelGroups } from './LabelGroups';
import { Labels } from './Labels';
import { Users } from './Users';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  const hasPermissionsToViewLabelGroups = useCanAccessView(
    VIEWS.LABEL_GROUPS_LIST,
  );

  return (
    <Routes>
      <Route element={<TabsPagesLayout />}>
        <Route path="" element={<Users />} />
        <Route path="labels" element={<Labels />} />

        {hasPermissionsToViewLabelGroups && (
          <Route path="label-groups" element={<LabelGroups />} />
        )}
      </Route>

      <Route path="users/:userId" element={<UserDetails />} />
      <Route path="labels/:labelId" element={<LabelDetails />} />

      <Route path="*" element={<ErrorPage type="notFoundPage" />} />
    </Routes>
  );
};
Router.displayName = 'SettingsRouter';

export { Router };
