import { createContext } from 'react';

import type { UserFeature, UserResponse } from '~/modules/auth/lib/bff-client';

export type WmgSession = {
  user: UserResponse;
  token: string;
  features: UserFeature[];
};

const WmgSessionContext = createContext<WmgSession>({
  user: {
    permissions: {},
    name: '',
    email: '',
  },
  token: '',
  features: [],
});

WmgSessionContext.displayName = 'WmgSessionContext';

export { WmgSessionContext };
