type GroupInfoProps = { groups: string[] };

export const GroupInfo = ({ groups }: GroupInfoProps) => {
  if (!groups?.length) return null;

  const statusBaseCls = 'rounded-full flex-shrink-0 basis-4 w-sm h-sm ml-sm';

  return (
    <div className="flex items-center">
      <span className="flex-shrink-0 max-w-fit">{groups[0]}</span>
      {groups.length > 1 ? (
        <span className="ml-sm px-xs basis-4 rounded-xs leading-5 bg-level-05 text-body-xs text-center border border-emphasis-subtle">
          +{groups.length - 1}
        </span>
      ) : null}
      <span className={`${statusBaseCls}`} />
    </div>
  );
};

GroupInfo.displayName = 'GroupInfo';
