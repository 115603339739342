import type { LabelGroupListItem } from '~settings/api/lib/schemas';
import type { LabelGroupListQueryVariables } from '~settings/api/queries/labelGroupList';
import { useLabelGroupList } from '~settings/api/queries/labelGroupList';
import { Table } from '~settings/components/Table';
import { useLabelGroupsColumns } from '~settings/LabelGroups/useLabelGroupsColumns';

const TEMP_HARDCODED_VARIABLES: LabelGroupListQueryVariables = {
  skip: 0,
  take: 50,
};
const labelGroupEmptyArray: LabelGroupListItem[] = [];

export const LabelGroups = () => {
  const columns = useLabelGroupsColumns();
  const { isLoading, isError, labelGroupList } = useLabelGroupList(
    TEMP_HARDCODED_VARIABLES,
  );

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (isError) {
    // eslint-disable-next-line i18next/no-literal-string
    return <section className="py-md">Something went wrong.</section>;
  }

  return (
    <section className="py-lg">
      <Table
        data={labelGroupList?.data ?? labelGroupEmptyArray}
        columns={columns}
      />
    </section>
  );
};

LabelGroups.displayName = 'LabelGroups';
