import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { UserListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { Pagination } from '~settings/components/Pagination';
import { Table } from '~settings/components/Table';
import { Toolbar } from '~settings/components/Toolbar';
import { UserCard } from '~settings/components/UserCard';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { ViewType } from '~settings/constants';
import { usePagination } from '~settings/hooks/usePagination';
import { useUsersColumns } from '~settings/LabelDetails/hooks/useUsersColumns';

export const Users = ({
  users,
  isSublabel,
}: {
  users: UserListItem[];
  isSublabel: boolean;
}) => {
  const { t } = useTranslation('settings');
  const { page, handlePageChange, perPage } = usePagination();
  const columns = useUsersColumns();
  const navigate = useNavigate();
  const [viewType, setViewType] = useState(ViewType.LIST_VIEW);
  const onClick = (row: UserListItem) => {
    navigate(SETTINGS_ROUTES.USER_DETAIL(row.id));
  };
  const isListView = viewType === ViewType.LIST_VIEW;

  return (
    <>
      {isSublabel && (
        <HeaderSmall>{`${t('users')}(${users.length})`}</HeaderSmall>
      )}
      <Toolbar
        placeholder={t('searchUsers')}
        viewType={viewType}
        setViewType={setViewType}
      />
      <Pagination
        page={page}
        total={users.length}
        perPage={perPage}
        entity="users"
        onPageChange={handlePageChange}
      />
      {isListView ? (
        <Table
          data={users}
          columns={columns}
          rowClassName="odd:bg-level-03"
          onRowClick={onClick}
        />
      ) : (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(26rem,1fr))] gap-md">
          {users.map((user: UserListItem) => (
            <UserCard key={user.id} user={user} />
          ))}
        </div>
      )}
    </>
  );
};

Users.displayName = 'LabelUsers';
