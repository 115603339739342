import { useState } from 'react';

import { Icon } from '~/components';
import { mergeClasses } from '~/lib/mergeClasses';

import { ArtistFilterPanel } from './ArtistFilterPanel';
import type { ArtistFiltersType } from './ArtistFiltersType';

interface ArtistFilterButtonProps {
  selectedFilters: ArtistFiltersType;
  onApplyFilters: (filters: ArtistFiltersType) => void;
  onClick?: () => void;
}

const ArtistFilterButton = ({
  selectedFilters,
  onApplyFilters,
  onClick,
}: ArtistFilterButtonProps) => {
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const areFiltersApplied =
    selectedFilters.labels.length > 0 || selectedFilters.isCrossover;

  return (
    <>
      {showFilterPanel && (
        <ArtistFilterPanel
          originalFilters={selectedFilters}
          onClose={() => setShowFilterPanel(false)}
          onApplyFilters={onApplyFilters}
        />
      )}
      <button
        className={mergeClasses(
          'bg-alpha-default-emphasis',
          'outline',
          'outline-1',
          'outline-emphasis-subtle',
          'rounded-sm',
          'leading-none',
          'px-md',
          'hover:bg-alpha-strong-emphasis',
          'hover:outline-emphasis-stronger',
        )}
        onClick={() => {
          setShowFilterPanel(true);
          onClick && onClick();
        }}
        data-testid="artist-filter-button"
      >
        <Icon
          type="sliders-simple"
          variant={areFiltersApplied ? 'solid' : 'light'}
          size="md"
        />
      </button>
    </>
  );
};

ArtistFilterButton.displayName = 'ArtistFilterButton';
export { ArtistFilterButton };
