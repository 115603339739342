import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { LinkItemProps } from '~/modules/link-farm/components/LinkList/LinkItem.tsx';
import { LinkList } from '~/modules/link-farm/components/LinkList/LinkList.tsx';
import { instance } from '~/modules/monitoring/amplitude.ts';

import { PageTemplate } from './components/PageTemplate';
import linkFarmLinks from './linkFarmLinks.json';

const links = linkFarmLinks as unknown as Record<string, LinkItemProps[]>;

interface LinkPageProps {
  category: string;
}

export const LinkPage = ({ category }: LinkPageProps) => {
  const { t } = useTranslation('wmgone');

  useEffect(() => {
    return () => {
      instance.flush();
    };
  }, []);

  return (
    <PageTemplate>
      <LinkList
        title={t(`links.${category}`)}
        data={links[category || ''] || []}
      />
    </PageTemplate>
  );
};

LinkPage.displayName = 'LinkPage';
