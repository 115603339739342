import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import { LabelPerfPageLayout } from '~/modules/label-perf/components/LabelPerfPageLayout';

import { LabelPerf } from './components/LabelPerf';

/**
 * Top-level router combining module-level routers
 */
const Router = () => {
  return (
    <Suspense>
      <Routes>
        <Route element={<LabelPerfPageLayout />}>
          <Route path="*" element={<LabelPerf />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

Router.displayName = 'LabelPerfRouter';

export { Router };
