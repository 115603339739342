import { QueryClient } from '@tanstack/react-query';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // 1 minute
        staleTime: 1000 * 60 * 1,
        retry: 0,
      },
      mutations: {
        retry: 0,
      },
    },
  });
}
