import type { LabelDetailQueryVariables } from '../queries/label';
import type { LabelGroupListQueryVariables } from '../queries/labelGroupList';
import type { LabelListQueryVariables } from '../queries/labelList';
import type { UserDetailQueryVariables } from '../queries/user';
import type { UserListQueryVariables } from '../queries/userList';

const user = () => [{ entity: 'user' }] as const;

const userLists = () => [{ ...user()[0], scope: 'list' }] as const;
const userList = (variables: UserListQueryVariables) =>
  [{ ...userLists()[0], variables }] as const;

const userDetails = () => [{ ...user()[0], scope: 'detail' }] as const;
const userDetail = (variables: UserDetailQueryVariables) =>
  [{ ...userDetails()[0], variables }] as const;

export const UserQueryKeys = {
  all: user,
  lists: userLists,
  list: userList,
  details: userDetails,
  detail: userDetail,
};

const label = () => [{ entity: 'label' }] as const;

const labelLists = () => [{ ...label()[0], scope: 'list' }] as const;
const labelList = (variables: LabelListQueryVariables) =>
  [{ ...labelLists()[0], variables }] as const;

const labelDetails = () => [{ ...label()[0], scope: 'detail' }] as const;
const labelDetail = (variables: LabelDetailQueryVariables) =>
  [{ ...labelDetails()[0], variables }] as const;

export const LabelQueryKeys = {
  all: label,
  lists: labelLists,
  list: labelList,
  details: labelDetails,
  detail: labelDetail,
};

const labelGroup = () => [{ entity: 'labelGroup' }] as const;

const labelGroupLists = () => [{ ...labelGroup()[0], scope: 'list' }] as const;
const labelGroupList = (variables: LabelGroupListQueryVariables) =>
  [{ ...labelGroupLists()[0], variables }] as const;

// TODO: details?
export const LabelGroupQueryKeys = {
  all: labelGroup,
  lists: labelGroupLists,
  list: labelGroupList,
};
