import { forwardRef, type HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '~/components';
import { mergeClasses } from '~/lib/mergeClasses';
import { instance } from '~/modules/monitoring/amplitude.ts';

import { ICON_BY_TYPE } from './LinkItemIcons';
import globalProtectIcon from './LinkItemIcons/globalProtectIcon.png?url';

export type LinkItemProps = HTMLAttributes<HTMLDivElement> & {
  icon: string;
  appName: string;
  actionText: string;
  url: string;
  requestAccess?: string;
  requireVpn?: boolean;
  className?: string;
};

const linkItemClasses = [
  'max-w-md',
  'h-full',
  'bg-default-inverse/6',
  'border',
  'border-white',
  'border-opacity-12',
  'rounded-sm',
  'flex',
  'flex-col',
  'p-lg',
  'w-full',
  'justify-between',
  'transition-colors',
  'tracking-wide',
  'group',
  'hover:border-white',
  'hover:border-opacity-50',
  'hover:bg-default-inverse/12',
  'focus:border-white',
  'focus:border-opacity-50',
  'focus:bg-default-inverse/12',
];
export const LinkItem = forwardRef<HTMLDivElement, LinkItemProps>(
  (
    {
      appName,
      icon,
      url,
      actionText,
      requestAccess,
      requireVpn,
      className = '',
    }: LinkItemProps,
    ref,
  ) => {
    const { t } = useTranslation('wmgone');

    const handleCardClick = () => {
      window.open(url, '_blank');
      instance.track('Link Farm Link Clicked', { appName, url });
    };

    return (
      <div
        role="button"
        aria-label={`Open ${appName}`}
        onClick={handleCardClick}
        className={mergeClasses(linkItemClasses, className)}
        ref={ref}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault();
            handleCardClick();
          }
        }}
      >
        <div className="relative flex flex-row justify-between items-center w-full">
          <img
            src={ICON_BY_TYPE[icon]}
            className="rounded-full w-2xl h-2xl transition-opacity group-hover:opacity-0 group-focus:opacity-0"
            alt={`${appName} icon`}
          />
          <div
            aria-hidden
            className="absolute flex w-[2.5em] h-[2.5em] bg-default-inverse/6 justify-center items-center rounded-full transition-opacity opacity-0 group-hover:opacity-100 group-focus:opacity-100"
          >
            <Icon
              type="external-link"
              size="lg"
              variant="regular"
              role="presentation"
              aria-hidden
              aria-label="external-url-icon"
              className="text-white"
            />
          </div>

          <div
            aria-describedby={`vpn-required-${appName}`}
            className={`flex-row items-center gap-[0.25em] transition-opacity opacity-0 group-hover:opacity-100 group-focus:opacity-100 ${requireVpn ? 'flex' : 'hidden'}`}
          >
            <img
              src={globalProtectIcon}
              className="rounded-full w-[1.4em] h-[1.4em]"
              alt="global-protect-icon"
            />
            <p className="text-[0.75em]">{t('links.item.vpnRequired')}</p>
          </div>
        </div>
        <div className="w-full">
          <p className="text-start text-[1em] leading-[1em] mt-[1.5em] h-[2.5em]">
            {actionText}
          </p>
          <div className="flex flex-row justify-between items-end text-[0.75em]">
            <div className="bg-default-inverse/12 py-[0.25em] px-[0.5em] rounded-xs max-w-[11.5em] text-left">
              {appName}
            </div>
            {requestAccess && (
              <p>
                <a
                  aria-roledescription="button"
                  aria-label={`Request access to ${appName}`}
                  className="underline hover:text-hover transition-colors duration-100"
                  href={requestAccess}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.stopPropagation();
                    instance.track('Link Farm Link Request Access', {
                      appName,
                      url,
                    });
                  }}
                >
                  {t('links.item.requestAccess')}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    );
  },
);

LinkItem.displayName = 'LinkItem';
