import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';

import { Icon, Tag } from '~/components';
import { Spinner } from '~/components/Spinner';

import { getArtistDetail, getCountryName } from '../../lib/artists';
import { socialIconMap } from '../ArtistColumns';

export function ArtistDetails() {
  const { t } = useTranslation('roster');
  const { uaid } = useParams<{ uaid: string }>();

  const {
    data: artist,
    error,
    isLoading: isLoadingArtist,
  } = useQuery({
    enabled: !!uaid,
    queryKey: ['artist-details', uaid],
    queryFn: () => {
      if (uaid) {
        return getArtistDetail({
          uaid,
        });
      }
    },
  });

  if (!uaid || isNaN(Number(uaid))) {
    return (
      <div className="flex justify-start items-center p-2xl w-full">
        <p className="font-header text-display-xl m-0 leading-none text-default">
          {t('errors.invalidArtistId')}
        </p>
      </div>
    );
  }

  // TODO: Add system notification component
  if (error) {
    return (
      <div className="flex justify-start items-center p-2xl w-full">
        <p className="font-header text-display-xl m-0 leading-none text-default">
          {t('errors.loadingArtist')}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between items-center">
      <div className="flex flex-col justify-between items-center gap-lg w-full">
        <div className="flex justify-start items-center w-full gap-sm text-body-xs">
          <Link
            to="/roster"
            className="underline font-semibold text-hover hover:text-white transition"
          >
            {t('header.title')}
          </Link>
          <Icon
            className="text-subtle"
            type="chevron-right"
            size="sm"
            variant="solid"
            aria-hidden
          />
          <span>{artist?.name}</span>
        </div>
        {isLoadingArtist ? (
          <div className="flex justify-center items-center p-2xl w-full">
            <Spinner />
          </div>
        ) : (
          artist && (
            <>
              <div className="flex justify-center items-stretch gap-2xl w-full pb-xl">
                <img
                  src={artist.image}
                  alt={artist.name}
                  className={'w-[9.25rem] h-[9.25rem] object-cover'}
                />
                <div className="grow flex flex-col justify-center items-start gap-sm text-body-sm">
                  <h1 className="text-display-md font-bold text-tertiary-default">
                    {artist.name}
                  </h1>
                  <p>
                    {artist.countryOfOriginISO
                      ? getCountryName(
                          artist.countryOfOriginISO,
                          navigator.language,
                        )
                      : ''}
                  </p>
                  <p>
                    {t('details.labels')}:{' '}
                    <span>
                      {artist.labels.map((label) => label.name).join(', ')}
                    </span>
                  </p>
                  <div className="flex justify-between items-center mt-auto w-full">
                    <div className="flex justify-center items-center gap-xl">
                      {artist.socials.map((social) => {
                        const SocialIcon = socialIconMap[social.name];
                        return (
                          <a
                            key={`${social.name}_${social.url}`}
                            className={'flex gap-sm items-center'}
                            href={social.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="flex items-center justify-center h-md w-md">
                              <SocialIcon className="text-hover hover:text-white transition" />
                            </span>
                            <span className="sr-only">{social.name}</span>
                            {/* TODO: Add follower numbers here */}
                          </a>
                        );
                      })}
                    </div>
                    <div className="flex justify-center items-center gap-sm">
                      {artist.reports.map((report) => (
                        <a
                          key={report.name}
                          href={report.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Tag variant={'secondary'}>
                            {report.displayName ?? report.name}
                            <Icon
                              type="external-link"
                              size="sm"
                              variant="regular"
                              role="presentation"
                              aria-hidden
                              aria-label="external-url-icon"
                              className="ml-sm text-white"
                            />
                          </Tag>
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* TODO: Track list goes here */}
            </>
          )
        )}
      </div>
    </div>
  );
}

ArtistDetails.displayName = 'ArtistDetails';
