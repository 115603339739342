import { ArtistCrossOverTooltip } from './ArtistCrossOverTooltip';

export const ArtistCrossOverIcon = () => {
  return (
    <ArtistCrossOverTooltip>
      <span
        data-testid="artist-cross-over-icon"
        className="bg-tertiary-default text-strong-on-light text-opacity-1 text-center rounded-xs font-semibold text-body-xs px-sm py-xs"
      >
        C
      </span>
    </ArtistCrossOverTooltip>
  );
};

ArtistCrossOverIcon.displayName = 'ArtistCrossOverIcon';
