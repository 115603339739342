import * as RadixPopover from '@radix-ui/react-popover';
import { type ComponentProps, forwardRef } from 'react';

import { assertMultiSelect } from './assertions/multiSelect';
import { assertSingleSelect } from './assertions/singleSelect';
import { DropdownContent } from './components/DropdownContent';
import { DropdownInput } from './components/DropdownInput/DropdownInput';
import { DropdownTrigger } from './components/DropdownTrigger/DropdownTrigger';
import type { DropdownContextProviderProps } from './contexts/DropdownContext';
import {
  DropdownContextProvider,
  useDropdownContext,
} from './contexts/DropdownContext';

type DropdownWithoutProviderProps = {
  placeholder?: string;
  state?: ComponentProps<typeof DropdownTrigger>['state'];
  size?: ComponentProps<typeof DropdownTrigger>['size'];
  children?: React.ReactNode;
  contentClassName?: string;
};

function DropdownWithoutProvider({
  placeholder,
  state = 'default',
  size,
  children,
  contentClassName = '',
}: DropdownWithoutProviderProps) {
  const { isOpen, setIsOpen } = useDropdownContext();

  return (
    <>
      <DropdownInput invalid={state === 'error'} />
      <RadixPopover.Root open={isOpen} onOpenChange={setIsOpen}>
        <RadixPopover.Trigger asChild>
          <DropdownTrigger
            size={size}
            state={state}
            disabled={false}
            placeholder={placeholder}
          />
        </RadixPopover.Trigger>
        <RadixPopover.Portal>
          <RadixPopover.Content className={contentClassName}>
            <DropdownContent>{children}</DropdownContent>
          </RadixPopover.Content>
        </RadixPopover.Portal>
      </RadixPopover.Root>
    </>
  );
}

DropdownWithoutProvider.displayName = 'DropdownWithoutProvider';

export type DropdownProps<TMultiselect extends boolean | undefined> = Omit<
  DropdownContextProviderProps<TMultiselect>,
  'children'
> &
  DropdownWithoutProviderProps;

function DropdownWithoutRef<TMultiselect extends boolean | undefined = false>(
  {
    options,
    multiselect,
    displaySelectedOptions,
    onChange,
    value,
    ...props
  }: DropdownProps<TMultiselect>,
  ref: React.Ref<HTMLInputElement>,
) {
  assertSingleSelect({ multiselect, value, displaySelectedOptions });
  assertMultiSelect({ multiselect, value });

  return (
    <DropdownContextProvider
      options={options}
      multiselect={multiselect}
      displaySelectedOptions={displaySelectedOptions}
      onChange={onChange}
      value={value}
      inputRef={ref}
    >
      <DropdownWithoutProvider {...props} />
    </DropdownContextProvider>
  );
}

DropdownWithoutRef.displayName = 'Dropdown';

export const Dropdown = forwardRef(DropdownWithoutRef) as <
  TMultiselect extends boolean | undefined = false,
>(
  props: DropdownProps<TMultiselect> & {
    ref?: React.ForwardedRef<HTMLInputElement>;
  },
) => ReturnType<typeof DropdownWithoutRef<TMultiselect>>;
