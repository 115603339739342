import { useTranslation } from 'react-i18next';

import { Icon, Separator, Tag, Tooltip } from '~/components';
import type { LabelDetail } from '~settings/api/lib/schemas';

import { HeaderSmall } from '../components/HeaderSmall';

interface DistributionTerritoriesProps {
  distributionGroups: LabelDetail['distributionGroups'];
}

export const DistributionTerritories = ({
  distributionGroups,
}: DistributionTerritoriesProps) => {
  const { t } = useTranslation('settings');

  if (!distributionGroups?.length) return null;

  return (
    <div>
      <Separator className="my-lg" />
      <HeaderSmall>{t('distributionTerritories')}</HeaderSmall>
      <ul className="flex flex-wrap gap-y-sm gap-x-xs">
        {distributionGroups.map((group) => {
          const countries = group.distributionGroupCountries
            .map(({ country }) => country.name)
            .join(', ');

          return (
            <Tag key={group.id} variant="secondary" size="lg" asChild>
              <li>
                <span>{group.name}</span>
                <Tooltip content={countries} side="top" delayDuration={0}>
                  <Icon
                    type="circle-info"
                    variant="light"
                    className="text-subtlest ml-xs"
                    size="md"
                  />
                </Tooltip>
              </li>
            </Tag>
          );
        })}
      </ul>
    </div>
  );
};

DistributionTerritories.displayName = 'DistributionTerritories';
