import type { LabelData } from '~/modules/artist-roster/lib/types';

export type ArtistFiltersType = {
  labels: LabelData[];
  isCrossover: boolean;
};

export const DEFAULT_ARTIST_FILTERS: ArtistFiltersType = {
  labels: [],
  isCrossover: false,
};
