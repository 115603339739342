import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { DetailPageError } from '~/modules/errors/components/DetailPageError';
import { useUser } from '~settings/api/queries/user';
import { ContentBox } from '~settings/components/ContentBox';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { UserLabels } from '~settings/UserDetails/UserLabels';

import { UserDetailsBlock } from './parts/UserDetailsBlock';
import { UserHeader } from './parts/UserHeader';

type UserDetailsRouteParams = {
  userId: string;
};

export const UserDetails = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const { userId } = useParams<UserDetailsRouteParams>();
  const { isLoading, error, user } = useUser(userId || '');

  useEffect(() => {
    if (!userId) {
      navigate(SETTINGS_ROUTES.ROOT);
    }
  }, [userId, navigate]);

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (error || !user) {
    return (
      <DetailPageError
        errorCode={error?.response?.status}
        fallbackRoute={{
          href: SETTINGS_ROUTES.ROOT,
          label: t('backToUserManagement'),
        }}
      />
    );
  }

  return (
    <>
      <UserHeader name={user.fullName} />

      <article className="py-md flex gap-md">
        <section className="text-body-sm flex-none basis-[20%] min-w-[19rem] inline-flex flex-col gap-md">
          <UserDetailsBlock user={user} />
        </section>
        <ContentBox className="flex-1 rounded-sm py-lg">
          <UserLabels labels={user.labels} />
        </ContentBox>
      </article>
    </>
  );
};

UserDetails.displayName = 'UserDetails';
