import { useOktaAuth } from '@okta/okta-react';
import { useContext } from 'react';

import { WmgSessionContext } from '../contexts';

const useWmgSession = () => {
  const { authState } = useOktaAuth();
  const {
    user: { permissions, name },
    token,
    features,
  } = useContext(WmgSessionContext);

  // authState.accessToken.claims.sub should exist by the time we get to this
  // line of code, but the return type is string | undefined, so defaulting to
  // an empty string avoids needing to cast or similar.

  // NOTE: this email could instead come from the WmgSessionContext, but is currently
  // coming from the Okta Access token.
  const email = authState?.accessToken?.claims?.sub ?? '';

  return { email, permissions, name, token, features };
};

export { useWmgSession };
