import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { useId } from 'react';

import { mergeClasses } from '~/lib/mergeClasses';

import { Icon } from '../Icon';

type RootProps = Omit<
  React.ComponentProps<typeof RadixCheckbox.Root>,
  'defaultState'
>;

type CheckboxProps = {
  invalid?: boolean;
  label?: string;
} & Required<Pick<RootProps, 'checked'>> &
  RootProps;

export function Checkbox({
  checked,
  invalid,
  className,
  disabled,
  label,
  ...props
}: CheckboxProps) {
  const id = useId();
  const isChecked = checked === true || checked === 'indeterminate';
  return (
    <label
      htmlFor={id}
      aria-disabled={disabled}
      className={mergeClasses(
        'text-default',
        'text-caption-md',
        'font-normal',
        'cursor-pointer',
        invalid && 'text-error-default',
        disabled && 'text-disabled',
        disabled && 'cursor-not-allowed',
        'gap-sm',
        'flex',
        'items-center',
        className,
      )}
    >
      <RadixCheckbox.Root
        id={id}
        className={mergeClasses(
          'relative',
          'rounded-xs',
          'border',
          'border-current',
          'border-px',
          'outline',
          'outline-2',
          'outline-transparent',
          'outline-offset-[-1px]',
          'w-md',
          'h-md',
          'transition',
          'transition-all',
          'hover:outline-current',
          'focus:outline-current',
          isChecked && 'bg-primary-default',
          isChecked && 'text-primary-default',
          invalid && isChecked && 'bg-error-default',
          invalid && isChecked && 'text-error-default',
          'disabled:cursor-not-allowed',
          'disabled:text-disabled',
          'disabled:bg-transparent',
          'disabled:outline-transparent',
        )}
        checked={checked}
        disabled={disabled}
        {...props}
      >
        <RadixCheckbox.Indicator>
          <Icon
            type={checked === 'indeterminate' ? 'minus' : 'check'}
            className={mergeClasses(
              'text-default',
              'absolute',
              'inset-0',
              'translate-y-1/2',
              disabled && 'text-[var(--color-ghost-white-900)]',
              !disabled && invalid && 'text-black',
            )}
            size="sm"
          />
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      {label}
    </label>
  );
}

Checkbox.displayName = 'Checkbox';
