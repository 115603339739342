import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { UserQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import { type UserListResponse, UserListResponseSchema } from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';

// TODO: define once Backend pagination, sorting and filtering is supported
export interface UserListQueryVariables {
  skip: number;
  take: number;
}

// TODO: once pagination is ready, explore https://tanstack.com/query/latest/docs/framework/react/guides/paginated-queries
export function useUserList<TData = UserListResponse>(
  variables: UserListQueryVariables,
  options?: PresetUseQueryOptions<UserListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: UserQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await BFFClient.get('users', {
        headers: getRequestHeaders(session.token),
        searchParams: {
          skip: variables.skip,
          take: variables.take,
        },
      }).json();

      return UserListResponseSchema.parse(responseData);
    },
    ...options,
  });

  return { userList: data, ...query };
}
