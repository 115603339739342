import { Outlet } from 'react-router-dom';

export function ArtistPageLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}

ArtistPageLayout.displayName = 'ArtistPageLayout';
