import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { LabelGroupQueryKeys } from '../config/queryKeys';
import { getRequestHeaders } from '../lib/headers';
import { LegacyCoopClient } from '../lib/LegacyCoopClient';
import {
  type LabelGroupListResponse,
  LabelGroupListResponseSchema,
} from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';

// TODO: define once Backend pagination, sorting and filtering is supported
export interface LabelGroupListQueryVariables {
  skip: number;
  take: number;
}

// TODO: migrate to BFFClient once new BE API is ready
// TODO: once pagination is ready, explore https://tanstack.com/query/latest/docs/framework/react/guides/paginated-queries
export function useLabelGroupList<TData = LabelGroupListResponse>(
  variables: LabelGroupListQueryVariables,
  options?: PresetUseQueryOptions<LabelGroupListResponse, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: LabelGroupQueryKeys.list(variables),
    queryFn: async () => {
      const responseData = await LegacyCoopClient.get('labels/groups', {
        headers: getRequestHeaders(session.token),
        searchParams: {
          skip: variables.skip,
          take: variables.take,
        },
      }).json();

      return LabelGroupListResponseSchema.parse(responseData);
    },
    ...options,
  });

  return { labelGroupList: data, ...query };
}
