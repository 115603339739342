import { HttpStatusCode } from '~/modules/settings/api/lib/types';

import { ErrorContent, type ErrorContentProps } from '../ErrorContent';

interface DetailPageErrorProps {
  errorCode?: number;
  fallbackRoute?: ErrorContentProps['fallbackRoute'];
}

export function DetailPageError({
  errorCode,
  fallbackRoute,
}: DetailPageErrorProps) {
  switch (errorCode) {
    case HttpStatusCode.NotFound:
      return <ErrorContent type="notFound" fallbackRoute={fallbackRoute} />;
    case HttpStatusCode.Forbidden:
    case HttpStatusCode.Unauthorized:
      return <ErrorContent type="noAccess" fallbackRoute={fallbackRoute} />;
    default:
      return <ErrorContent type="default" fallbackRoute={fallbackRoute} />;
  }
}
DetailPageError.displayName = 'DetailPageError';
