import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Separator } from '~/components';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import { DetailPageError } from '~/modules/errors/components/DetailPageError';
import { useLabel } from '~settings/api/queries/label';
import { ContentBox } from '~settings/components/ContentBox';
import { Tabs } from '~settings/components/Tabs';
import { SETTINGS_ROUTES } from '~settings/config/routes';
import { LabelDetailsHeader } from '~settings/LabelDetails/LabelDetailsHeader';
import { transformLabelDetails } from '~settings/utils/transformers';

import { DistributionTerritories } from './DistributionTerritories';
import { LabelAdmins } from './LabelAdmins';
import { LabelGroups } from './LabelGroups';
import { LabelMetadata } from './LabelMetadata';
import { Socials } from './Socials';
import { Sublabels } from './Sublabels';
import { Users } from './Users';

type LabelDetailsRouteParams = {
  labelId: string;
};

export const LabelDetails = () => {
  const { labelId } = useParams<LabelDetailsRouteParams>();
  const navigate = useNavigate();
  const { t } = useTranslation('settings');
  const { isLoading, error, label } = useLabel(labelId || '', {
    select: transformLabelDetails,
  });
  const isCurrentUserExternal = useIsExternalUser();

  useEffect(() => {
    if (!labelId) {
      navigate(SETTINGS_ROUTES.LABEL_LIST);
    }
  }, [labelId, navigate]);

  const tabs = useMemo(() => {
    if (!label) {
      return [];
    }

    return [
      {
        title: `Sublabels (${label.sublabelsCount})`,
        component: <Sublabels sublabels={label.sublabels} />,
      },
      {
        title: `Users (${label.usersCount})`,
        component: <Users users={label.users} isSublabel={label.isSublabel} />,
      },
    ];
  }, [label]);

  if (isLoading) {
    return <section className="py-md">Loading...</section>;
  }

  if (error || !label) {
    return (
      <DetailPageError
        errorCode={error?.response?.status}
        fallbackRoute={{
          href: SETTINGS_ROUTES.LABEL_LIST,
          label: t('backToUserManagement'),
        }}
      />
    );
  }

  return (
    <>
      <LabelDetailsHeader data={label} />
      <article className="py-md flex gap-md">
        <section className="text-body-sm flex-none basis-[20%] min-w-[19rem] inline-flex flex-col gap-md">
          <ContentBox>
            {isCurrentUserExternal ? (
              <>
                {!label.parentId ? (
                  <>
                    <LabelAdmins adminUsers={label.adminUsers} />
                    <Socials socials={label.socials} />
                  </>
                ) : (
                  <>
                    <LabelMetadata data={label} />
                    <LabelAdmins adminUsers={label.adminUsers} />
                    <Socials socials={label.socials} />
                  </>
                )}
              </>
            ) : (
              <>
                <LabelMetadata data={label} />
                <DistributionTerritories
                  distributionGroups={label.distributionGroups}
                />
                <LabelGroups labelGroups={label.labelGroups} />
                <Separator className="my-lg" />
                <LabelAdmins adminUsers={label.adminUsers} />
                <Socials socials={label.socials} />
              </>
            )}
          </ContentBox>
        </section>
        <ContentBox className="flex-1 rounded-sm py-lg">
          {label.parentId ? (
            <Users users={label.users} isSublabel={label.isSublabel} />
          ) : (
            <Tabs tabs={tabs} />
          )}
        </ContentBox>
      </article>
    </>
  );
};
LabelDetails.displayName = 'LabelDetails';
