import { z } from 'zod';

export const RoleSchema = z.enum([
  'LABEL_ADMIN',
  'RELEASE_COORDINATOR',
  'VIDEO_COORDINATOR',
  'OPS_ADMIN',
]);
export type Role = z.infer<typeof RoleSchema>;

// TODO: add `skip` and `take` (based on the legacy Co-Op API?)
export const PaginatedResponseSchema = <TData>(data: z.ZodSchema<TData>) =>
  z.object({
    total: z.number(),
    data: z.array(data),
  });
export type PaginatedResponse<TData> = z.infer<
  ReturnType<typeof PaginatedResponseSchema<TData>>
>;

export const LabelSchema = z.object({
  id: z.string(),
  name: z.string(),
  isParentLabel: z.boolean(),
  parent: z
    .object({
      id: z.string(),
      name: z.string(),
    })
    .nullable(),
  roles: z.array(RoleSchema),
});
export type Label = z.infer<typeof LabelSchema>;

export const LabelGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
});
export type LabelGroup = z.infer<typeof LabelGroupSchema>;

// TODO: missing items: isExternal
export const BaseUserSchema = z.object({
  id: z.string(),
  fullName: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  isActive: z.boolean(),
  email: z.string(),
  lastLogin: z.string().nullable(),
});

export const UserListItemSchema = BaseUserSchema.extend({
  labels: z.array(LabelSchema),
  labelGroups: z.array(LabelGroupSchema),
  roles: z.array(RoleSchema),
});
export type UserListItem = z.infer<typeof UserListItemSchema>;

export const UserListResponseSchema =
  PaginatedResponseSchema(UserListItemSchema);
export type UserListResponse = z.infer<typeof UserListResponseSchema>;

export const UserDetailSchema = BaseUserSchema.extend({
  phone: z.string().nullable(),
  labels: z.array(LabelSchema),
});
export type UserDetail = z.infer<typeof UserDetailSchema>;

export const LabelListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  isActive: z.boolean(),
  isParentLabel: z.boolean(),
  usersCount: z.number(),
  sublabelsCount: z.number(),
  labelGroups: z.array(LabelGroupSchema),
});
export type LabelListItem = z.infer<typeof LabelListItemSchema>;

export const LabelListResponseSchema =
  PaginatedResponseSchema(LabelListItemSchema);
export type LabelListResponse = z.infer<typeof LabelListResponseSchema>;

export const LabelDetailSchema = z.object({
  id: z.string(),
  name: z.string(),
  active: z.boolean(),
  parentId: z.string().nullable(),
  parentName: z.string().nullable(),
  weaLabelCode: z.string(),
  dealOwner: z.string(),
  pmo: z.string().nullable(),
  profitCenter: z.string().nullable(),
  marketingCompany: z.string().nullable(),
  usLabelCode: z.string().nullable(),
  dealScope: z.string().nullable(),
  gpiNumber: z.string().nullable(),
  dealId: z.string().nullable(),
  scopeId: z.string().nullable(),
  dealRegionScope: z.string(),
  facebookReference: z.string().nullable(),
  twitterReference: z.string().nullable(),
  youtubeReference: z.string().nullable(),
  instagramReference: z.string().nullable(),
  website: z.string().nullable(),
  distributionGroups: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        distributionGroupCountries: z.array(
          z.object({
            country: z.object({
              id: z.string(),
              name: z.string(),
            }),
          }),
        ),
      }),
    )
    .nullable(),
  labelGroups: z.array(LabelGroupSchema),
  numberOfLabelGroups: z.number().nullable(),
  sublabels: z.array(LabelListItemSchema),
  sublabelsCount: z.number(),
  users: z.array(UserListItemSchema),
  usersCount: z.number(),
});
export type LabelDetail = z.infer<typeof LabelDetailSchema>;

export const LabelGroupListItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string(),
  createdBy: z.string(),
  createdByName: z.string(),
  notes: z.string().nullable(),
  numberOfLabels: z.number(),
  numberOfUsers: z.number(),
});
export type LabelGroupListItem = z.infer<typeof LabelGroupListItemSchema>;

export const LabelGroupListResponseSchema = PaginatedResponseSchema(
  LabelGroupListItemSchema,
);
export type LabelGroupListResponse = z.infer<
  typeof LabelGroupListResponseSchema
>;
