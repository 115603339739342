import { useTranslation } from 'react-i18next';

import { Icon, Tag } from '~/components';
import { Avatar } from '~/components/Avatar';
import { Breadcrumbs } from '~/modules/navigation/components/Breadcrumbs';
import type { LabelDetail } from '~settings/api/lib/schemas';
import { SETTINGS_ROUTES } from '~settings/config/routes';

export const LabelDetailsHeader = ({ data }: { data: LabelDetail }) => {
  const { t } = useTranslation('settings');
  const { name } = data;
  const isSublabel = Boolean(data.parentId);

  return (
    <header className="flex flex-col gap-lg">
      <Breadcrumbs.Root>
        <Breadcrumbs.Item to={SETTINGS_ROUTES.ROOT}>
          <Icon type="gear" size="sm" variant="regular" className="mr-sm" />
          {t('title')}
        </Breadcrumbs.Item>
        <Breadcrumbs.Item to={SETTINGS_ROUTES.LABEL_LIST}>
          {t('labels')}
        </Breadcrumbs.Item>
        {isSublabel && data.parentId ? (
          <Breadcrumbs.Item to={SETTINGS_ROUTES.LABEL_DETAIL(data.parentId)}>
            {data.parentName}
          </Breadcrumbs.Item>
        ) : null}
        <Breadcrumbs.Item>{name}</Breadcrumbs.Item>
      </Breadcrumbs.Root>

      <div className="flex items-center gap-md text-strong-on-dark">
        <Avatar
          name={name}
          variant={isSublabel ? 'secondary' : 'primary'}
          size="lg"
          shape="square"
        />
        <h1 className="text-display-sm">{name}</h1>
        {isSublabel ? (
          <Tag variant="secondary" size="sm">
            {t('sublabel')}
          </Tag>
        ) : (
          <Tag variant="primary" size="sm">
            {t('parentLabel')}
          </Tag>
        )}
      </div>
    </header>
  );
};
LabelDetailsHeader.displayName = 'LabelDetailsHeader';
