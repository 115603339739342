import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import type { LabelListItem } from '~settings/api/lib/schemas';
import { LabelName } from '~settings/components/LabelName';

interface UseLabelsColumns {
  labelTypeFilter?: 'parent' | 'sublabel';
}

export const useLabelsColumns = ({
  labelTypeFilter,
}: UseLabelsColumns = {}) => {
  const isCurrentUserExternal = useIsExternalUser();
  const { t } = useTranslation('settings');

  return useMemo(() => {
    const columnHelper = createColumnHelper<LabelListItem>();

    const sublabelsColumns = {
      name: columnHelper.accessor('name', {
        id: 'name',
        header: () => t('label'),
        cell: (cell) => <LabelName name={cell.getValue()} />,
      }),
      labelType: columnHelper.accessor('isParentLabel', {
        id: 'type',
        header: () => t('labelType'),
        cell: (cell) => t(cell.getValue() ? 'parentLabel' : 'sublabel'),
      }),
      labelGroup: columnHelper.accessor((row) => row.labelGroups[0]?.name, {
        id: 'labelGroup',
        header: () => t('labelGroups'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
      usersCount: columnHelper.accessor('usersCount', {
        id: 'users',
        header: () => t('users'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
      sublabelsCount: columnHelper.accessor('sublabelsCount', {
        id: 'labels',
        header: () => t('sublabels'),
        cell: (cell) => (cell.getValue() ? cell.getValue() : '-'),
      }),
    };

    let columnsOrder: Array<keyof typeof sublabelsColumns> = [];

    if (isCurrentUserExternal) {
      columnsOrder = ['name', 'labelType', 'usersCount', 'sublabelsCount'];
    } else {
      switch (labelTypeFilter) {
        case 'sublabel':
          columnsOrder = ['name', 'labelType', 'usersCount', 'sublabelsCount'];
          break;
        case 'parent':
        default:
          columnsOrder = [
            'name',
            'labelType',
            'labelGroup',
            'usersCount',
            'sublabelsCount',
          ];
          break;
      }
    }

    return columnsOrder.map((key) => sublabelsColumns[key]);
  }, [isCurrentUserExternal, labelTypeFilter, t]);
};
