import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export const usePagination = (perPage: number = 10) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page') || '1', 10);

  const handlePageChange = (newPage: number) => {
    const currentParams = new URLSearchParams(searchParams);
    currentParams.set('page', newPage.toString());
    setSearchParams(currentParams);
  };
  return {
    page,
    handlePageChange,
    perPage,
  };
};

export const usePaginationMessage = (
  page: number,
  perPage: number,
  total: number,
  entity: string,
) => {
  const { t } = useTranslation('settings');

  const translatedEntity = t(entity);

  const start = useMemo(() => (page - 1) * perPage + 1, [page, perPage]);
  const end = useMemo(
    () => Math.min(page * perPage, total),
    [page, perPage, total],
  );

  const message = t('pagination.label', {
    start,
    end,
    total,
    entity: translatedEntity,
  });

  return message;
};
