import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Tag } from '~/components';
import type { UserListItem } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';

import { SETTINGS_ROUTES } from '../config/routes';

interface LabelAdminsProps {
  adminUsers: UserListItem[];
}

export const LabelAdmins = ({ adminUsers }: LabelAdminsProps) => {
  const { t } = useTranslation('settings');

  if (!adminUsers?.length) return null;

  return (
    <div>
      <HeaderSmall>{t('labelAdmins')}</HeaderSmall>
      <ul className="flex flex-wrap gap-y-sm gap-x-xs">
        {adminUsers.map((user) => (
          <Tag key={user.id} variant="secondary" size="lg" asChild>
            <li>
              <Link
                to={SETTINGS_ROUTES.USER_DETAIL(user.id)}
                className="flex items-center group"
              >
                <span className="bg-emphasis-strong group-hover:bg-emphasis-default text-caption-sm p-xs rounded-full items-center mr-sm">{`${user.firstName[0]}${user.lastName[0]}`}</span>
                <span>{user.fullName}</span>
              </Link>
            </li>
          </Tag>
        ))}
      </ul>
    </div>
  );
};

LabelAdmins.displayName = 'LabelAdmins';
