import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Separator, Tag } from '~/components';
import { Button } from '~/components/Button';
import { useIsExternalUser } from '~/hooks/useIsExternalUser';
import type { LabelDetail } from '~settings/api/lib/schemas';
import { HeaderSmall } from '~settings/components/HeaderSmall';
import { PropertyList } from '~settings/components/PropertyList';
import { SETTINGS_ROUTES } from '~settings/config/routes';

export const LabelMetadata = ({ data }: { data: LabelDetail }) => {
  const { t } = useTranslation('settings');
  const [showAll, setShowAll] = useState(false);
  const isCurrentUserExternal = useIsExternalUser();

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div>
      <HeaderSmall>{t('details')}</HeaderSmall>
      <PropertyList.Root>
        {!isCurrentUserExternal ? (
          <>
            {data.weaLabelCode && (
              <PropertyList.Item>
                <PropertyList.Key>{t('weaLabelCode')}</PropertyList.Key>
                <PropertyList.Value>{data.weaLabelCode}</PropertyList.Value>
              </PropertyList.Item>
            )}
            {data.pmo && (
              <PropertyList.Item>
                <PropertyList.Key>{t('pmo')}</PropertyList.Key>
                <PropertyList.Value>{data.pmo}</PropertyList.Value>
              </PropertyList.Item>
            )}
            {data.profitCenter && (
              <PropertyList.Item>
                <PropertyList.Key>{t('profitCenter')}</PropertyList.Key>
                <PropertyList.Value>{data.profitCenter}</PropertyList.Value>
              </PropertyList.Item>
            )}
            {data.marketingCompany && (
              <PropertyList.Item>
                <PropertyList.Key>{t('marketingCompany')}</PropertyList.Key>
                <PropertyList.Value>{data.marketingCompany}</PropertyList.Value>
              </PropertyList.Item>
            )}
            {data.parentId && (
              <PropertyList.Item>
                <PropertyList.Key>{t('labelType')}</PropertyList.Key>
                <PropertyList.Value>
                  {t(data.parentId ? 'sublabel' : 'parentLabel')}
                </PropertyList.Value>
              </PropertyList.Item>
            )}
            {data.parentId && (
              <PropertyList.Item>
                <PropertyList.Key>{t('parentLabel')}</PropertyList.Key>
                <PropertyList.Value>
                  <Tag variant="secondary" size="sm" asChild>
                    <Link to={SETTINGS_ROUTES.LABEL_DETAIL(data.parentId)}>
                      {data.parentName}
                    </Link>
                  </Tag>
                </PropertyList.Value>
              </PropertyList.Item>
            )}
            {showAll && (
              <>
                {data.usLabelCode && (
                  <PropertyList.Item>
                    <PropertyList.Key>{t('usLabelCode')}</PropertyList.Key>
                    <PropertyList.Value>{data.usLabelCode}</PropertyList.Value>
                  </PropertyList.Item>
                )}
                {data.dealScope && (
                  <PropertyList.Item>
                    <PropertyList.Key>{t('dealScope')}</PropertyList.Key>
                    <PropertyList.Value>{data.dealScope}</PropertyList.Value>
                  </PropertyList.Item>
                )}
                {data.gpiNumber && (
                  <PropertyList.Item>
                    <PropertyList.Key>{t('gpiNumber')}</PropertyList.Key>
                    <PropertyList.Value>{data.gpiNumber}</PropertyList.Value>
                  </PropertyList.Item>
                )}
                {data.dealId && (
                  <PropertyList.Item>
                    <PropertyList.Key>{t('dealID')}</PropertyList.Key>
                    <PropertyList.Value>{data.dealId}</PropertyList.Value>
                  </PropertyList.Item>
                )}
                {data.scopeId && (
                  <PropertyList.Item>
                    <PropertyList.Key>{t('scopeID')}</PropertyList.Key>
                    <PropertyList.Value>{data.scopeId}</PropertyList.Value>
                  </PropertyList.Item>
                )}
              </>
            )}
            <PropertyList.Item>
              <Button onClick={toggleShowAll} variant="text">
                {showAll ? t('Show Less') : t('Show All')}
              </Button>
            </PropertyList.Item>
          </>
        ) : (
          data.parentId && (
            <PropertyList.Item>
              <PropertyList.Key>{t('parentLabel')}</PropertyList.Key>
              <PropertyList.Value>
                <Tag variant="secondary" size="sm" asChild>
                  <Link to={SETTINGS_ROUTES.LABEL_DETAIL(data.parentId)}>
                    {data.parentName}
                  </Link>
                </Tag>
              </PropertyList.Value>
            </PropertyList.Item>
          )
        )}
        {isCurrentUserExternal && <Separator className="mb-lg mt-sm" />}
      </PropertyList.Root>
    </div>
  );
};

LabelMetadata.displayName = 'LabelMetadata';
