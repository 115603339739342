import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMiniSearch } from 'react-minisearch';

import { Icon } from '~/components';
import { Modal } from '~/components/Modal';
import { Spinner } from '~/components/Spinner';

import links from '../../linkFarmLinks.json';
import { LinkItem, type LinkItemProps } from '../LinkList/LinkItem';

interface LinkSearchProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LinkSearch = ({ isOpen, onClose }: LinkSearchProps) => {
  const { t } = useTranslation('wmgone');
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<(HTMLDivElement | null)[]>([]);

  const linkFarmItems: LinkItemProps[] = useMemo(() => {
    return Object.values(links)
      .flat()
      .map((item, index) => ({
        minisearchID: index,
        icon: item.icon,
        appName: item.appName,
        url: item.url,
        actionText: item.actionText,
        category: item.category,
        requestAccess: item.requestAccess,
        requireVpn: item.requireVpn,
      }));
  }, []);

  const { search, searchResults } = useMiniSearch(linkFarmItems, {
    fields: ['appName', 'actionText', 'category', 'description'],
    storeFields: [
      'appName',
      'actionText',
      'category',
      'url',
      'icon',
      'path',
      'requestAccess',
      'requireVpn',
    ],
    idField: 'minisearchID',
  });

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      if (!query) {
        setIsLoading(false);
        return;
      }

      search(query, { fuzzy: 0.2, prefix: true });
      setIsLoading(false);
      setSelectedIndex(0);
    }, 300);

    return () => clearTimeout(timer);
  }, [query, linkFarmItems, search]);

  useEffect(() => {
    if (isOpen) {
      setQuery('');
      setSelectedIndex(0);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  }, [isOpen]);

  useEffect(() => {
    const selectedItem = resultsRef.current[selectedIndex];
    if (selectedItem) {
      selectedItem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (!searchResults) {
      return;
    }
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) =>
        Math.min(prevIndex + 1, searchResults.length - 1),
      );
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    } else if (event.key === 'Enter' && searchResults[selectedIndex]) {
      window.open(searchResults[selectedIndex].url, '_blank');
    } else if (event.key === 'Tab') {
      event.preventDefault();
      inputRef.current?.select();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={`absolute top-[-40vh] flex flex-col items-center justify-center max-h-[75vh] line-[1.125rem] text-default max-w-[380px] w-[50vw] bg-transparent`}
      >
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-sm">
            <span className="w-[1.75rem] h-[1.75rem] flex items-center justify-center">
              <Icon
                type="search"
                variant="solid"
                size="md"
                className="text-white"
                aria-hidden
              />
            </span>
          </div>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            ref={inputRef}
            placeholder="Search by tool name"
            className={`w-full border-px bg-level-03 py-[0.75rem] pl-2xl pr-md text-body-sm italic placeholder:text-disabled border-white border-opacity-12 ${
              query ? 'rounded-t-sm' : 'rounded-sm'
            }`}
            aria-controls="link-farm-dropdown"
            aria-haspopup="listbox"
            aria-label="Search tools"
            onKeyDown={handleKeyDown}
          />
        </div>
        {!!query && (
          <div className="overflow-y-auto w-full">
            <div
              role="listbox"
              id="link-farm-dropdown"
              aria-live="polite"
              className="flex items-center justify-center flex-col gap-md w-full z-50 bg-level-03 px-md py-[1.25rem] border-px border-white border-opacity-19 rounded-b-sm"
            >
              {searchResults?.length ? (
                searchResults.map((item, index) => (
                  <LinkItem
                    key={item.actionText + index}
                    ref={(el) => (resultsRef.current[index] = el)}
                    {...item}
                    className={`${index === selectedIndex ? 'bg-default-inverse/19 border-white border-opacity-50' : ''}`}
                    tabIndex={-1}
                    role="option"
                    aria-selected={index === selectedIndex}
                  />
                ))
              ) : isLoading ? (
                <div className="flex items-center justify-center w-full px-md py-xs bg-transparent">
                  <div className="flex items-center justify-center w-lg">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className="flex items-center justify-center w-full px-md py-[0.75rem] bg-transparent">
                  {t('links.search.noResults')}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
LinkSearch.displayName = 'LinkSearch';

export const LinkSearchTrigger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('wmgone');

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="flex items-center justify-center gap-sm px-md py-xs h-fit text-body-sm text-white rounded-full border-px border-default bg-transparent duration-100 hover:bg-default-inverse/12 active:bg-level-03 max-w-[166px]"
      >
        <Icon type="search" variant="solid" size="md" aria-hidden />
        <span>{t('links.search.trigger')}</span>
      </button>
      <LinkSearch
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
};
LinkSearchTrigger.displayName = 'LinkSearchTrigger';
