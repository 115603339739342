import type { Header, SortDirection } from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import type { HTMLProps, KeyboardEventHandler } from 'react';

import { Icon, Tooltip } from '~/components';
import { mergeClasses } from '~/lib/mergeClasses';

type TableHeadCellProps<TData, TValue> = HTMLProps<HTMLTableCellElement> & {
  sortDirection?: SortDirection;
  header: Header<TData, TValue>;
  info?: string;
};

export function TableHeadCell<TData, TValue>({
  header,
  className,
  info,
  ...props
}: TableHeadCellProps<TData, TValue>) {
  const isSortable = header.column.getCanSort();
  const sortDirection = header.column.getIsSorted();

  const toggleSort = (e: unknown) => {
    if (isSortable) {
      header.column.getToggleSortingHandler()?.(e);
    }
  };

  const onKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      toggleSort(e);
    }
  };

  return (
    <th
      className={mergeClasses(
        'px-md',
        'py-sm',
        'text-subtle',
        'text-body-sm',
        'font-normal',
        'select-none',
        'hover:text-strong',
        isSortable && 'cursor-pointer',
        className,
      )}
      tabIndex={0}
      onClick={toggleSort}
      onKeyDown={onKeyDown}
      {...props}
    >
      <div className="flex items-center gap-sm">
        {flexRender(header.column.columnDef.header, header.getContext())}
        {isSortable && !sortDirection && (
          <Icon type="sort" variant="regular" aria-label="not sorted" />
        )}
        {sortDirection && (
          <div
            className="flex"
            role="img"
            aria-label={`sorted ${sortDirection === 'asc' ? 'ascending' : 'descending'}`}
          >
            <Icon
              aria-hidden
              type="sort-up"
              variant={sortDirection === 'asc' ? 'solid' : 'regular'}
            />
            <Icon
              aria-hidden
              type="sort-down"
              variant={sortDirection === 'desc' ? 'solid' : 'regular'}
              className="transform -translate-x-full"
            />
          </div>
        )}
        {info && (
          <Tooltip content={info} side="top" delayDuration={0}>
            <Icon
              aria-label="info"
              type="circle-info"
              variant="light"
              className="text-subtlest ml-sm"
              size="sm"
            />
          </Tooltip>
        )}
      </div>
    </th>
  );
}

TableHeadCell.displayName = 'TableHeadCell';
