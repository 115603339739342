import type { UseQueryOptions } from '@tanstack/react-query';
import type { HTTPError } from 'ky';

export enum HttpStatusCode {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
}

export type PresetUseQueryOptions<TQueryFnData, TData = TQueryFnData> = Omit<
  UseQueryOptions<TQueryFnData, HTTPError, TData>,
  'queryKey' | 'queryFn'
>;
