import type { DropdownProps } from '../Dropdown';

export const assertSingleSelect = <TMultiselect extends boolean>({
  multiselect,
  value,
  displaySelectedOptions,
}: Pick<
  DropdownProps<TMultiselect>,
  'multiselect' | 'value' | 'displaySelectedOptions'
>) => {
  if (multiselect) {
    return;
  }
  if (displaySelectedOptions) {
    console.warn(
      'displaySelectedOptions is only available for multiselect dropdown',
    );
  }
  if (Array.isArray(value)) {
    throw new Error("value can't be an array for single select dropdown");
  }
};
