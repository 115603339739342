import { Layout } from './components/Layout';
import { Router } from './router';

export function App() {
  return (
    <Layout>
      <Router />
    </Layout>
  );
}

App.displayName = 'LabelPerfApp';
