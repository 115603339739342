import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { UserQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import { type UserDetail, UserDetailSchema } from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';

export interface UserDetailQueryVariables {
  userId: string;
}

export function useUser<TData = UserDetail>(
  userId: UserDetailQueryVariables['userId'],
  options?: PresetUseQueryOptions<UserDetail, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: UserQueryKeys.detail({ userId }),
    queryFn: async () => {
      const data = await BFFClient.get(`users/${userId}`, {
        headers: getRequestHeaders(session.token),
      }).json();

      return UserDetailSchema.parse(data);
    },
    enabled: Boolean(userId),
    ...options,
  });

  return { user: data, ...query };
}
