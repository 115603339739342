import type { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '~/components';

export const ArtistCrossOverTooltip = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('roster');
  return (
    <Tooltip
      content={
        <div className="leading-3">
          {t('crossover.title')}:<br />
          {t('crossover.description')}
        </div>
      }
      size="sm"
      align="start"
    >
      {children}
    </Tooltip>
  );
};

ArtistCrossOverTooltip.displayName = 'ArtistCrossOverTooltip';
