import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '~/components/Button';
import { Dropdown } from '~/components/Dropdown';
import { IconButton } from '~/components/IconButton';
import { Label } from '~/components/Label/Label';
import { Separator } from '~/components/Separator';
import { mergeClasses } from '~/lib/mergeClasses';
import { getLabels } from '~/modules/artist-roster/lib/labels';

import { ArtistCrossoverFilter } from './ArtistCrossoverFilter';
import type { ArtistFiltersType } from './ArtistFiltersType';
import { DEFAULT_ARTIST_FILTERS } from './ArtistFiltersType';

interface ArtistFilterPanelProps {
  originalFilters: ArtistFiltersType;
  onClose: () => void;
  onApplyFilters: (filters: ArtistFiltersType) => void;
}

const ArtistFilterSidePanel = ({
  originalFilters,
  onClose,
  onApplyFilters,
}: ArtistFilterPanelProps) => {
  const { t } = useTranslation('roster');

  const [filtersToApply, setFiltersToApply] = useState(originalFilters);
  const { data } = useQuery({
    queryKey: ['labels'],
    queryFn: () => getLabels({}),
  });
  const options =
    data?.map((labelData) => {
      return { value: labelData.labelId, label: labelData.name };
    }) || [];

  const onSelectLabel = useCallback(
    (selectedLabels: string[]) => {
      const selectedLabelFilters =
        data?.filter((labelData) =>
          selectedLabels.includes(labelData.labelId),
        ) || [];
      setFiltersToApply({
        ...filtersToApply,
        labels: selectedLabelFilters,
      });
    },
    [data, filtersToApply, setFiltersToApply],
  );

  const onSelectCrossoverFilter = useCallback(
    (isCrossover: boolean) => {
      setFiltersToApply({
        ...filtersToApply,
        isCrossover,
      });
    },
    [filtersToApply, setFiltersToApply],
  );

  const onClickApply = useCallback(() => {
    onApplyFilters(filtersToApply);
    onClose();
  }, [onApplyFilters, onClose, filtersToApply]);

  const isApplyButtonDisabled =
    originalFilters.labels.length === filtersToApply.labels.length &&
    originalFilters.labels.every((value) =>
      filtersToApply.labels.includes(value),
    ) &&
    originalFilters.isCrossover === filtersToApply.isCrossover;

  return (
    <div
      className={mergeClasses(
        'fixed',
        'top-0',
        'right-0',
        'w-[400px]',
        'h-full',
        'bg-level-02',
      )}
    >
      <div className="flex flex-col gap-lg px-lg py-lg">
        <div className="flex justify-between">
          <div className="text-headline-md font-bold">{t('Filter')}</div>
          <IconButton
            iconType="x"
            iconVariant="regular"
            size="medium"
            onClick={onClose}
          />
        </div>
        <div className="flex flex-col gap-sm w-fill">
          <Label>{t('Label')}</Label>
          <Dropdown
            placeholder="Select record label"
            options={options}
            multiselect={true}
            displaySelectedOptions={true}
            onChange={onSelectLabel}
            value={filtersToApply.labels.map((label) => label.labelId)}
            contentClassName="z-10"
          />
        </div>
        <Separator />
        <div>
          <Label>{t('Artist type')}</Label>
          <ArtistCrossoverFilter
            isCrossoverFilterSelected={filtersToApply.isCrossover}
            onSelect={onSelectCrossoverFilter}
          />
        </div>
        <Separator />
      </div>

      <div
        className={mergeClasses(
          'flex justify-end',
          'gap-lg',
          'absolute',
          'bottom-0',
          'border-t-px',
          'border-white',
          'border-opacity-12',
          'w-full',
          'py-md',
          'px-lg',
        )}
      >
        <Button
          size="large"
          variant="tertiary"
          disabled={
            filtersToApply.labels.length === 0 && !filtersToApply.isCrossover
          }
          onClick={() => {
            setFiltersToApply(DEFAULT_ARTIST_FILTERS);
          }}
        >
          {t('Clear filters')}
        </Button>
        <Button
          size="large"
          disabled={isApplyButtonDisabled}
          onClick={onClickApply}
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  );
};

ArtistFilterSidePanel.displayName = 'ArtistFilterSidePanel';

const ArtistFilterPanel = (props: ArtistFilterPanelProps) => {
  const blurredRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    blurredRef.current?.addEventListener('click', props.onClose);
  });

  return (
    <div className="fixed top-0 left-0 w-full h-full z-10">
      <div
        ref={blurredRef}
        className="w-full h-full backdrop-blur"
        data-testid="artist-filter-panel-blur-overlay"
      ></div>
      <ArtistFilterSidePanel {...props} />
    </div>
  );
};

ArtistFilterPanel.displayName = 'ArtistFilterPanel';
export { ArtistFilterPanel };
