export const SETTINGS_ROUTES = {
  ROOT: '/settings',

  USER_DETAIL: (userId: string) => `/settings/users/${userId}` as const,

  LABEL_LIST: '/settings/labels',
  LABEL_DETAIL: (labelId: string) => `/settings/labels/${labelId}` as const,

  LABEL_GROUP_LIST: '/settings/label-groups',
} as const;
