import { URL_PREFIX } from '../constants/api';
import type { LabelPerfModel, LabelPerfResponse } from '../types/label-perf';

export const getLabelPerfReport = async (
  majorLabels: string[],
): Promise<LabelPerfModel> => {
  try {
    const url = `${URL_PREFIX}/api/v1/exec-view/kpi-report`;
    const body = JSON.stringify({
      major_labels: majorLabels,
    });

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch report: ${response.statusText}`);
    }

    const r = (await response.json()) as LabelPerfResponse;

    const labelPerf: LabelPerfModel = {
      totalAssets: r.tracks,
      totalGaps: r.tracks_need_review,
      overallMonthlyRevenue: r.monthly_revenue,
      overallMonthlyRevenueChange: r.monthly_revenue_trend,
      overallMonthlyStreams: r.monthly_streams,
      overallMonthlyStreamsChange: r.monthly_streams_trend,
      opportunityRevenueFromFixes: r.opportunity_revenue_from_fixes ?? 0,
      revenueFromFixes: r.monthly_revenue_from_fixes,
      revenueFromFixesChange: r.monthly_revenue_trend_from_fixes,
      streamsFromFixes: r.monthly_streams_from_fixes,
      streamsFromFixesChange: r.monthly_streams_trend_from_fixes,
    };

    return labelPerf;
  } catch (error) {
    console.warn('Failed to get report:', error);
    throw error;
  }
};
