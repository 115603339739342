import type React from 'react';
import { useCallback, useState } from 'react';

import { Icon } from '~/components/Icon';
import { IconButton } from '~/components/IconButton';
import { mergeClasses } from '~/lib/mergeClasses';

interface ArtistSearchBarProps {
  initialValue?: string;
  placeholder: string;
  onSubmit: (value: string) => void;
  className?: string;
}

const ArtistSearchBar = ({
  initialValue,
  placeholder,
  onSubmit,
  className = '',
}: ArtistSearchBarProps) => {
  const [inputValue, setInputValue] = useState(initialValue || '');

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setInputValue(event.target.value);
    },
    [],
  );

  const onClear = useCallback(() => {
    setInputValue('');
    onSubmit('');
  }, [setInputValue, onSubmit]);

  return (
    <form
      className={mergeClasses(
        'flex items-center',
        'bg-alpha-default-emphasis',
        'outline',
        'outline-1',
        'outline-emphasis-subtle',
        'w-full',
        'py-sm',
        'px-sm',
        'gap-sm',
        'rounded-sm',
        'focus-within:bg-alpha-strong-emphasis',
        'focus-within:outline-emphasis-stronger',
        'hover:bg-alpha-strong-emphasis',
        'hover:outline-emphasis-strong',
        'hover:focus-within:outline-emphasis-stronger',
        className,
      )}
      role="search"
      aria-label="Search artists by name"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(inputValue);
      }}
    >
      <Icon
        className={mergeClasses(
          inputValue ? 'text-default' : 'text-subtle',
          'py-md',
          'px-sm',
        )}
        type="search"
        variant="light"
        size="sm"
        aria-hidden
      />
      <input
        className={mergeClasses(
          'bg-transparent',
          'focus:ring-none',
          'focus:outline-none',
          'flex-grow',
          'italic',
          'text-default',
          'text-body-sm',
        )}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        aria-label="Search input for artist name"
      />
      {inputValue && (
        <IconButton
          type="button"
          iconType="circle-x"
          size="small"
          onClick={() => {
            onClear();
          }}
          aria-label="Clear search input"
        />
      )}
    </form>
  );
};

ArtistSearchBar.displayName = 'ArtistSearchBar';

export { ArtistSearchBar };
