import { mergeClasses } from '~/lib/mergeClasses';

import { useDropdownContext } from '../../contexts/DropdownContext';
import { useItemEventHandlers } from '../../hooks/useItemEventHandlers';
import { DropdownItem } from '../DropdownItem';

type DropdownContentProps = {
  children?: React.ReactNode;
};

export function DropdownContent({ children }: DropdownContentProps) {
  const { collection, multiselect, isSelected } = useDropdownContext();
  const createHandlers = useItemEventHandlers();
  return (
    <div
      role="listbox"
      className={mergeClasses([
        'rounded-xs',
        'bg-level-01/100',
        'w-[var(--radix-popover-trigger-width)]',
        'max-h-[20rem]',
        'mt-xs',
        'overflow-auto',
        'shadow',
        'border',
        'border-emphasis-strong',
      ])}
    >
      {children}
      {collection.map(({ label, value, ref }) => (
        <DropdownItem
          key={value}
          checked={isSelected(value)}
          isCheckbox={multiselect}
          {...createHandlers(value)}
          ref={ref}
        >
          {label}
        </DropdownItem>
      ))}
    </div>
  );
}

DropdownContent.displayName = 'DropdownContent';
