import type { HTMLAttributes } from 'react';

import type { VariantProps } from '~/lib/variants';
import { variants } from '~/lib/variants';

const avatarVariants = variants({
  base: [
    'flex',
    'items-center',
    'justify-center',
    'flex-shrink-0',
    'font-semibold',
  ],
  variants: {
    variant: {
      primary: ['text-strong-on-light', 'bg-primary-default'],
      secondary: ['text-strong-on-light', 'bg-secondary-default'],
      base: ['text-strong-on-dark', 'bg-level-03'],
    },
    size: {
      lg: ['w-[56px]', 'h-[56px]', 'text-display-sm'],
      md: ['w-2xl', 'h-2xl', 'text-body-md'],
      sm: ['w-[28px]', 'h-[28px]', 'text-body-xs'],
    },
    shape: {
      circle: ['rounded-full'],
      square: ['rounded-sm'],
    },
    hasLongInitials: {
      true: [],
      false: [],
    },
  },
  defaultVariants: {
    variant: 'base',
    size: 'md',
    shape: 'circle',
  },
  compoundVariants: [
    {
      size: 'lg',
      hasLongInitials: true,
      className: 'text-body-md',
    },
    {
      size: 'md',
      hasLongInitials: true,
      className: 'text-body-xs',
    },
    {
      size: 'sm',
      hasLongInitials: true,
      className: 'text-caption-sm',
    },
  ],
});

const getInitials = (name: string) => {
  const initials = name
    .split(' ')
    .map((word) => word[0])
    .join('');
  return initials.toLocaleUpperCase();
};

export interface AvatarProps
  extends HTMLAttributes<HTMLSpanElement>,
    VariantProps<typeof avatarVariants> {
  name: string;
}

export function Avatar({
  name,
  variant = 'base',
  size = 'md',
  shape = 'circle',
  ...props
}: AvatarProps) {
  const displayName = getInitials(name);
  const variantClasses = avatarVariants({
    variant,
    size,
    shape,
    hasLongInitials: displayName.length > 2,
  });

  return (
    <span className={variantClasses} {...props}>
      {displayName}
    </span>
  );
}
Avatar.displayName = 'Avatar';
