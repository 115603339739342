import type { ColumnDef } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icon } from '~/components';
import { env } from '~/lib/env.ts';
import { cn } from '~/lib/mergeClasses';
import { Skeleton } from '~/modules/artist-roster/components/Skeleton';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '~/modules/artist-roster/components/Table';
import { instance } from '~/modules/monitoring/amplitude.ts';

import type { ArtistType } from '../lib/types';
import type { ArtistTableMeta } from './ArtistColumns.tsx';

interface ArtistDataTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<ArtistType, any>[];
  data: ArtistType[];
  isLoading: boolean;
  meta?: ArtistTableMeta;
}

interface SkeletonTableProps {
  size: number;
  headers: { id: string }[];
}

const SkeletonTable: FC<SkeletonTableProps> = ({ size, headers }) => {
  const items = useMemo(() => Array(size).fill(0), [size]);

  return items.map((_, i) => (
    <TableRow key={i} data-testid={`skeleton-table-row-${i}`}>
      {headers.map((cell) => (
        <TableCell
          key={cell.id}
          className={cn(
            'py-md first:rounded-l-sm last:rounded-r-sm first:pl-lg',
            i % 2 === 0 && 'bg-level-05',
          )}
        >
          <div className="space-y-sm">
            <Skeleton className="bg-level-02 w-[12em] h-md rounded-sm" />
            <Skeleton className="bg-level-03 w-[10em] h-md rounded-sm" />
          </div>
        </TableCell>
      ))}
    </TableRow>
  ));
};

export function ArtistDataTable({
  columns,
  data,
  meta,
  isLoading,
}: ArtistDataTableProps) {
  const { t } = useTranslation('roster');
  const navigate = useNavigate();
  const table = useReactTable({
    data,
    columns,
    meta,
    getCoreRowModel: getCoreRowModel(),
  });
  const artistDetailsEnabled =
    env('VITE_WMG_ARTIST_DETAILS_ENABLED') === 'true';

  return (
    <Table aria-label="Artists roster list" className={'text-left w-full'}>
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead
                  key={header.id}
                  className={'text-body-sm font-normal first:pl-lg'}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {isLoading ? (
          <SkeletonTable
            size={10}
            headers={table.getHeaderGroups()[0].headers}
          />
        ) : table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row, index) => (
            <TableRow
              aria-label={`Row for ${row.original?.name || 'artist'}`}
              key={row.id}
              data-state={row.getIsSelected() && 'selected'}
              className={
                artistDetailsEnabled
                  ? 'cursor-pointer hover:bg-default-inverse/6 duration-0'
                  : ''
              }
              onClick={() => {
                if (artistDetailsEnabled) {
                  const meta = table.options?.meta as
                    | ArtistTableMeta
                    | undefined;
                  instance.track('roster_table_artist_click', {
                    artist_id: row.original.id,
                    artist_name: row.original.name,
                    roster_label_filters_count: meta?.filtersCount,
                    roster_crossover_filter_applied: false,
                    roster_label_filters_applied: meta?.filtersApplied,
                  });
                  navigate(`/roster/${row.original.uaid}`);
                }
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  scope="row"
                  className={cn(
                    'py-md first:rounded-l-sm last:rounded-r-sm first:pl-lg',
                    index % 2 === 0 && 'bg-default-inverse/6',
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <div className="w-full flex flex-col justify-center items-center gap-lg py-5xl mt-md tracking-wider border-2xs border-dashed border-strong-on-light">
                <span className="flex items-center justify-center shrink-0">
                  <Icon
                    type="user-music"
                    variant="light"
                    size="4x"
                    aria-hidden
                    className="text-default"
                  />
                </span>
                <div className="w-full flex flex-col justify-center items-center gap-sm">
                  <p className="text-body-lg font-semibold text-white">
                    {t('noData.title')}
                  </p>
                  <p className="text-body-md font-normal text-subtle">
                    {t('noData.sub')}
                  </p>
                </div>
              </div>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

ArtistDataTable.displayName = 'ArtistDataTable';
