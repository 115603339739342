import { useQuery } from '@tanstack/react-query';

import { useWmgSession } from '~/shared/hooks';

import { LabelQueryKeys } from '../config/queryKeys';
import { BFFClient } from '../lib/BFFClient';
import { getRequestHeaders } from '../lib/headers';
import { type LabelDetail, LabelDetailSchema } from '../lib/schemas';
import type { PresetUseQueryOptions } from '../lib/types';

export interface LabelDetailQueryVariables {
  labelId: string;
}

export function useLabel<TData = LabelDetail>(
  labelId: LabelDetailQueryVariables['labelId'],
  options?: PresetUseQueryOptions<LabelDetail, TData>,
) {
  const session = useWmgSession();

  const { data, ...query } = useQuery({
    queryKey: LabelQueryKeys.detail({ labelId }),
    queryFn: async () => {
      const data = await BFFClient.get(`labels/${labelId}`, {
        headers: getRequestHeaders(session.token),
      }).json();

      return LabelDetailSchema.parse(data);
    },
    enabled: Boolean(labelId),
    ...options,
  });

  return { label: data, ...query };
}
