import ky from 'ky';

/**
 * @deprecated Prefer to use the new API (BFFClient) instead of this one, if the endpoint is available.
 */
export const LegacyCoopClient = ky.create({
  prefixUrl: '/api/coop',
  credentials: 'include',
  // 30 seconds
  timeout: 1000 * 30,
  // Retries are also handled by the React Query Client
  retry: 0,
});
