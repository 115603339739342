/* istanbul ignore file */
/* eslint @typescript-eslint/no-use-before-define: 0 */

// All of this code is intentionally not covered by tests because it's 3P code
// that we should not be modifying or testing. We should look for a long-term
// solution that doesn't require us to have a set of copy-pasted code that
// should actually be an NPM package owned by another service.
import type {
  PulseInsightsContext,
  PulseInsightsUpdateOptions,
  PulseInsightsWindow,
} from './PulseInsights.types.ts';

export const initPi = (token: string, context: PulseInsightsContext): void => {
  if (!token) {
    return;
  }

  const w: PulseInsightsWindow = window;

  if (w.isPiInitiated) {
    updatePi(context, { token });
    return;
  }
  w.isPiInitiated = true;

  const s = document.createElement('script');
  s.async = true;
  s.src = '//js.pulseinsights.com/surveys.js';
  const f = document.getElementsByTagName('script')[0];
  f.parentNode?.insertBefore(s, f);

  class PulseInsightsCommands extends Array {
    push(...commands: unknown[]) {
      commands.forEach((c) => w.PulseInsightsObject?.processCommand(c));
      return 0;
    }
  }

  function waitPulseInsightsObject(): Promise<unknown> {
    // eslint-disable-next-line promise/param-names
    const timeoutPromise = new Promise((_, reject) => setTimeout(reject, 5000));
    const waitPromise = new Promise<void>(function (resolve) {
      function wait() {
        if (typeof w.PulseInsightsObject === 'object') {
          resolve();
        } else {
          setTimeout(wait, 100);
        }
      }
      wait();
    });
    return Promise.race([timeoutPromise, waitPromise]);
  }

  waitPulseInsightsObject()
    // eslint-disable-next-line promise/always-return
    .then(function () {
      w.pi = function (...args) {
        if (w.pi) {
          w.pi.commands = w.pi.commands || new PulseInsightsCommands();
          w.pi.commands.push(args);
        }
      };
      w.pi('host', 'survey.pulseinsights.com');
      w.pi('identify', token);
      w.pi('pushBeforeGet', true);

      updatePi(context, { token });
    })
    .catch(() => {
      console.error('Failed to initialize window.PulseInsightsObject');
    });
};

let updateTimeoutId: NodeJS.Timeout | null = null;

export const updatePi = (
  context: PulseInsightsContext,
  { token, ...options }: PulseInsightsUpdateOptions,
): void => {
  const retries = options.retries ?? 50;
  const retryDelay = options.retryDelay ?? 100;
  const w: PulseInsightsWindow = window;

  // Init Pulse Insights instead of update
  if (!w.isPiInitiated) {
    initPi(token || '', context);
    return;
  }

  // Cleanup old update
  if (updateTimeoutId) {
    clearTimeout(updateTimeoutId);
    updateTimeoutId = null;
  }

  // Pulse Insights API is not ready yet and there is no more retries
  if (!w.pi && retries <= 0) {
    console.error(
      'Cannot change the context for Pulse Insights. API is not ready.',
    );
    return;
  }

  // Pulse Insights API is not ready yet so set timeout to try again
  if (!w.pi) {
    updateTimeoutId = setTimeout(() => {
      updatePi(context, { retries: retries - 1, retryDelay, token });
    }, retryDelay);
    return;
  }

  try {
    const { localizationCode = null, userId = null, ...rest } = context;
    const contextData = w.PulseInsightsObject?.customData || {};
    Object.assign(contextData, rest);
    contextData.localizationCode = localizationCode;
    w.pi('identify_client', userId);
    w.pi('set_context_data', contextData);
    w.pi('get', 'surveys');
  } catch (e) {
    console.error('Cannot change the context for Pulse Insights', e);
  }
};
