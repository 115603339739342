import { useOktaAuth } from '@okta/okta-react';
import {
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { ErrorPage as ErrorScreen } from '~/components/ErrorPage';
import { convertCoopLocale } from '~/i18n';
import { env } from '~/lib/env';
import type { UserFeature, UserResponse } from '~/modules/auth/lib/bff-client';
import { bffClient } from '~/modules/auth/lib/bff-client';
import { BrandContext } from '~/modules/branding';
import { useShowNav } from '~/modules/navigation';
import { LoadingScreen } from '~/modules/navigation/components/LoadingScreen';

import { WmgSessionContext } from './WmgSessionContext';

const WmgSessionProvider = ({ children }: PropsWithChildren) => {
  const { authState } = useOktaAuth();
  const brand = useContext(BrandContext);
  const [error, setError] = useState<string | undefined>();
  const [user, setUser] = useState<UserResponse | undefined>();
  const { setShowNav, setShowByLocale } = useShowNav();
  const [userFeatures, setUserFeatures] = useState<UserFeature[] | []>([]);

  const useContainerNav =
    env('VITE_USE_CONTAINER_NAVIGATION', false) === 'true';
  const bffGracefulFallback =
    env('VITE_BFF_GRACEFUL_FALLBACK', false) === 'true';
  const [authAttempted, setAuthAttempted] = useState(false);

  const token = authState?.accessToken?.accessToken;
  const oktaUserId = authState?.accessToken?.claims?.uid as string;

  const authenticateUser = useCallback(
    async (token?: string, oktaUserId?: string) => {
      if (!token || !oktaUserId) {
        return;
      }

      setError(undefined);

      if (useContainerNav) {
        try {
          const fetchedUser = await bffClient.getUser(token);
          const userInfo = await bffClient.getUserInfo(token, oktaUserId);
          setUser(fetchedUser);
          setUserFeatures(userInfo.userFeatures);
          setShowByLocale(convertCoopLocale(userInfo.userLocale));
        } catch (e) {
          if (brand.name === 'ADA' && bffGracefulFallback) {
            setShowNav(false);
          } else {
            setError((e as Error).message || 'Unexpected error');
            setShowNav(true);
          }
        } finally {
          setAuthAttempted(true);
        }
      } else {
        setShowNav(false);
        setAuthAttempted(true);
      }
    },
    [
      bffGracefulFallback,
      brand.name,
      setShowByLocale,
      setShowNav,
      useContainerNav,
    ],
  );

  useEffect(() => {
    authenticateUser(token, oktaUserId);
  }, [authenticateUser, token, oktaUserId]);

  if (error) {
    return (
      <ErrorScreen
        message={error}
        onRetry={() => authenticateUser(token, oktaUserId)}
      />
    );
  }

  if (
    authState?.accessToken?.accessToken &&
    !user &&
    useContainerNav &&
    !authAttempted
  ) {
    return <LoadingScreen />;
  }

  const wmgUser = user ?? {
    permissions: {},
    name: '',
    email: '',
  };

  return (
    <WmgSessionContext.Provider
      value={{
        user: wmgUser!,
        token: token ?? '',
        features: userFeatures ?? [],
      }}
    >
      {children}
    </WmgSessionContext.Provider>
  );
};

WmgSessionProvider.displayName = 'WmgSessionProvider';

export { WmgSessionContext, WmgSessionProvider };
