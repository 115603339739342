import type React from 'react';
import { useState } from 'react';

import { Button } from '~/components/Button';
import { Icon } from '~/components/Icon';
import { Input } from '~/components/Input/Input';
import type { ViewType } from '~settings/constants';

interface ToolbarProps {
  placeholder: string;
  viewType: string;
  setViewType: (viewType: ViewType) => void;
  rightAddon?: React.ReactNode;
}

export const Toolbar: React.FC<ToolbarProps> = ({
  placeholder,
  rightAddon,
}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = async (term: string) => {
    // handle search logic when we have the api available
    console.log(term);
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value;
    setSearchTerm(value);
    await handleSearch(value);
  };

  return (
    <div className="flex gap-md py-lg">
      <Button
        variant="tertiary"
        size="large"
        className="rounded-sm bg-level-03 px-md border border-emphasis-subtle hover:bg-level-04"
      >
        <Icon type="sliders-simple" variant="solid" size="md" />
      </Button>

      <div className="relative w-full group">
        <div className="absolute inset-y-0 start-0 flex items-center ps-sm pointer-events-none">
          <Icon
            type="search"
            size="md"
            className="text-subtle flex justify-center px-sm py-sm items-center align-center group-hover:text-default"
          />
        </div>
        <Input
          placeholder={placeholder}
          type="search"
          aria-controls="tool-bar-search"
          value={searchTerm}
          onChange={handleInputChange}
          rounded="small"
          className="ps-2xl bg-level-03 hover:bg-level-04 placeholder:text-subtle group-hover:placeholder:text-default !outline-none border border-emphasis-subtle"
        />
      </div>
      {rightAddon}
    </div>
  );
};

Toolbar.displayName = 'Toolbar';
