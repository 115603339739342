import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { SETTINGS_ROUTES } from '~settings/config/routes';
import { useCanAccessView, VIEWS } from '~settings/hooks/useCanAccessView';

import { Header } from './Header';
import { type NavTab, NavTabs } from './NavTabs';

export function TabsPagesLayout() {
  const { t } = useTranslation('settings');
  const canViewLabelGroups = useCanAccessView(VIEWS.LABEL_GROUPS_LIST);

  const tabs: NavTab[] = [
    { label: t('users'), path: SETTINGS_ROUTES.ROOT },
    { label: t('labels'), path: SETTINGS_ROUTES.LABEL_LIST },
  ];

  if (canViewLabelGroups) {
    tabs.push({
      label: t('labelGroups'),
      path: SETTINGS_ROUTES.LABEL_GROUP_LIST,
    });
  }

  return (
    <>
      <Header />
      <NavTabs tabs={tabs} ariaLabel={t('settingsTabLabel')} />
      <Outlet />
    </>
  );
}

TabsPagesLayout.displayName = 'TabsPagesLayout';
