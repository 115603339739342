import { useTranslation } from 'react-i18next';

export function Header() {
  const { t } = useTranslation('roster');
  return (
    <header className="items-center gap-md">
      <h1 className="text-display-md font-bold tracking-[0.031em]">
        {t('header.title')}
      </h1>
    </header>
  );
}

Header.displayName = 'Header';
